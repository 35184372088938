import * as React from 'react'
import {makeFilterValues} from 'routingAndDispatch/Setup/driverSettings/list/driverListSettingsHelpers'
import {CollapsibleFilters} from 'routingAndDispatch/common/CollapsibleFilter'
import {ExistingLandmarkType} from './hooks/useLocations'
import {CellContext} from '@tanstack/react-table'
import styled from 'styled-components/macro'
import {Link} from 'react-router'
import {sortStringsCaseInsensitive} from 'common/helpers'

export interface LocationListState {
  filters: CollapsibleFilters[]
  allLocations: ExistingLandmarkType[]
  filterValues: {name: string; value: string}[] | null
  filteredLocations: ExistingLandmarkType[]
  locationsToRender: ExistingLandmarkType[]
  filterIsOpen: boolean
  loading: boolean
  locationToDelete: ExistingLandmarkType | null
  showDeleteDialog: boolean
  showAddOrdersDialog: boolean
  selectedIdLocations: number[]
  showAddSingleOrderDialog: boolean
  selectedSingleRowIdLocations: number[]
}
export const locationListInitialState: LocationListState = {
  locationsToRender: [],
  filterValues: null,
  filters: [],
  allLocations: [],
  filteredLocations: [],
  filterIsOpen: false,
  loading: false,
  locationToDelete: null,
  showDeleteDialog: false,
  showAddOrdersDialog: false,
  selectedIdLocations: [],
  showAddSingleOrderDialog: false,
  selectedSingleRowIdLocations: [],
}

export type LocationListActionTypes =
  | {type: 'setState'; data: Partial<LocationListState>}
  | {type: 'loadLocations'; data: {locations: ExistingLandmarkType[]}}
  | {type: 'setFilterValues'; data: {name: string; value: string}}

export const locationListReducer = (
  state: LocationListState,
  action: LocationListActionTypes,
) => {
  switch (action.type) {
    case 'setState': {
      return {
        ...state,
        ...action.data,
      }
    }
    case 'loadLocations': {
      const locations = action.data.locations
      const tempLocationNameList = ['Display All']
      const tempContactNameList = ['Display All']
      const tempCityList = ['Display All']
      const tempStateList = ['Display All']

      locations.forEach(location => {
        location.Landmark_Name &&
          !tempLocationNameList.find(
            listLocation => listLocation === location.Landmark_Name,
          ) &&
          tempLocationNameList.push(location.Landmark_Name)

        location.ContactName &&
          !tempContactNameList.find(
            listContactName => listContactName === location.ContactName,
          ) &&
          tempContactNameList.push(location.ContactName)

        location.Landmark_City &&
          !tempCityList.find(listCity => listCity === location.Landmark_City) &&
          tempCityList.push(location.Landmark_City)

        location.Landmark_Region &&
          !tempStateList.find(
            listState => listState === location.Landmark_Region,
          ) &&
          tempStateList.push(location.Landmark_Region)
      })

      tempLocationNameList.sort(sortStringsCaseInsensitive)
      tempContactNameList.sort(sortStringsCaseInsensitive)
      tempCityList.sort(sortStringsCaseInsensitive)
      tempStateList.sort(sortStringsCaseInsensitive)

      const filterOptions: CollapsibleFilters[] = [
        {
          titleText: 'Location (Customer) Name',
          filterId: 'Location (Customer) Name',
          options: makeFilterValues(tempLocationNameList),
        },
        {
          titleText: 'Contact Name',
          filterId: 'Contact Name',
          options: makeFilterValues(tempContactNameList),
        },

        {
          titleText: 'City',
          filterId: 'City',
          options: makeFilterValues(tempCityList),
        },
        {
          titleText: 'State',
          filterId: 'State',
          options: makeFilterValues(tempStateList),
        },
      ]

      return {
        ...state,
        filters: filterOptions,
        allLocations: locations,
        filteredLocations: locations,
        locationsToRender: locations,
        initialLoad: true,
      }
    }
    case 'setFilterValues': {
      const tempArrayFilterValues = state.filterValues
        ? [...state.filterValues]
        : []

      tempArrayFilterValues.forEach((filter, index) => {
        //clears stale filter value and updates to new
        if (filter.name === action.data.name) {
          tempArrayFilterValues.splice(index, 1)
        }
      })
      if (action.data.value !== '') {
        tempArrayFilterValues.push({
          name: action.data.name,
          value: action.data.value,
        })
      }

      let tempLocationsArray = state.allLocations ? [...state.allLocations] : []
      if (tempArrayFilterValues.length > 0) {
        tempArrayFilterValues.forEach(filter => {
          if (filter.name === 'Location (Customer) Name') {
            tempLocationsArray = tempLocationsArray.filter(location => {
              return (
                filter.value === 'Display All' ||
                location.Landmark_Name === filter.value
              )
            })
          }
          if (filter.name === 'Contact Name') {
            tempLocationsArray = tempLocationsArray.filter(location => {
              return (
                filter.value === 'Display All' ||
                location.ContactName === filter.value
              )
            })
          }
          if (filter.name === 'City') {
            tempLocationsArray = tempLocationsArray.filter(location => {
              return (
                filter.value === 'Display All' ||
                location.Landmark_City === filter.value
              )
            })
          }
          if (filter.name === 'State') {
            tempLocationsArray = tempLocationsArray.filter(location => {
              return (
                filter.value === 'Display All' ||
                location.Landmark_Region === filter.value
              )
            })
          }
        })
      } else {
        tempLocationsArray = [...state.allLocations]
      }

      return {
        ...state,
        filterValues:
          tempArrayFilterValues.length > 0 ? tempArrayFilterValues : null,
        filteredLocations: tempLocationsArray,
      }
    }
    default:
      return {...state}
  }
}

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`
export const LocationLinkCell = ({
  instance,
  isRouteOptimization = false,
}: {
  instance: CellContext<ExistingLandmarkType, unknown>
  isRouteOptimization: boolean
}) => {
  if (instance.row.original.idLandmark) {
    const location = instance.row.original
    return (
      <StyledLink
        to={{
          pathname: `/locations/${location.idLandmark}`,
        }}
        state={{
          isRouteOptimization: isRouteOptimization,
          filters: {
            filters: [],
            globalFilter: '',
          },
        }}
      >{`${location.Landmark_Name}`}</StyledLink>
    )
  }
  return null
}
