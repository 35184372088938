import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {Opening4ResponseType} from 'common/types/opening4Response'

const getOpening4 = async () => {
  const {
    data,
  }: {
    data: Opening4ResponseType
  } = await Axios.get('/opening/4')
  return data
}

const useOpening4 = () => {
  const user = useStore(state => state.user)
  return useQuery(['opening4'], getOpening4, {
    enabled: Boolean(user?.token),
  })
}

export default useOpening4
