import * as React from 'react'
import {
  RoutePlanType,
  Segment,
  StopType,
} from 'routingAndDispatch/types/typesModule'
import {
  CalendarBlank,
  CheckCircle,
  Envelope,
  MapPin,
  MapPinLine,
  Phone,
  Timer,
  Truck,
  UserCircle,
} from 'phosphor-react'
import {differenceInMinutes, format, isSameDay, parseISO} from 'date-fns'
import {useStore} from 'common/useStore'
import {
  Column,
  EllipsisText,
  IconContainer,
  InfoPill,
  InfoText,
  Tooltip,
} from 'common/components'
import {
  CardColumn,
  DashedLineGray,
  DashedLineGreen,
  Detail,
  StopCard,
  StopColumn,
  StopNumber,
  StyledChatTeardrop,
  TimeText,
  StyledStatusBadge,
  StopCardMenuColumn,
  Spacer,
  TitleLink,
  RowWithGap,
  OrderDescription,
  TimeRow,
  TimeColumn,
  DifferentDayColumn,
  ExpandableBox,
  CardRowDouble,
  StyledCaretCircleUp,
  StyledCaretCircleDown,
  Bold,
  BoldPrimary,
  CardRowSingle,
  TruckIconHolder,
  StyledInfoPillContainer,
  ReadMoreOrLess,
} from '../routeDetailStyles'
import StopCardDropdown from './StopCardDropdown'
import {getHoursAndMinutes} from '../../Create/createRouteHelpers'
import {useNavigate} from 'react-router'
import ServiceTypeBadge from 'routingAndDispatch/common/ServiceTypeBadge'
import {convertToUsersTimeFormat, isValidDate} from 'common/helpersDateTime'
import {StopReducerAction} from 'routingAndDispatch/Stops/stopHelper'
import {getIsStopLate} from 'routingAndDispatch/common/RouteTimeTable/routePlanTimeTableHelpers'

interface StopCardProps {
  stop: StopType
  routePlan: RoutePlanType //needed to navigate back and forth between order detail
  planStatus: string
  index: number
  historyTrail: boolean
  segment: Segment
  currentStop: number
  idPlan: string
  planStartDateTime: Date | undefined
  refetch: () => void
  // routeHasArrivedStop: boolean
  routeDetailDispatch: (action: StopReducerAction) => void
  isSelected?: boolean
}
const getTooltip = (stop: StopType) => {
  return (
    <Column>
      <div>
        Estimated Start:{' '}
        {`${
          stop.ServiceStartDateTime
            ? convertToUsersTimeFormat(stop.ServiceStartDateTime)
            : ''
        }`}
      </div>
      <div>
        Estimated End:{' '}
        {`${
          stop.ServiceEndDateTime
            ? convertToUsersTimeFormat(stop.ServiceEndDateTime)
            : ''
        }`}
      </div>
    </Column>
  )
}
const StopPointCard: React.FC<StopCardProps> = ({
  stop,
  routePlan,
  planStatus,
  index,
  currentStop,
  segment,
  idPlan,
  planStartDateTime,
  refetch,
  // routeHasArrivedStop,
  routeDetailDispatch,
  isSelected,
}) => {
  const userConfig = useStore(state => state.userConfig)
  const [isOpen, setIsOpen] = React.useState(false)
  const [expandMessage, setExpandMessage] = React.useState(false)
  const [hasOverflow, setHasOverflow] = React.useState(false)
  const navigate = useNavigate()

  const cityStateAddress =
    stop?.city && stop?.state ? `${stop.city}, ${stop.state}` : ''
  const addressText = stop.address === ' ' ? cityStateAddress : ''
  const address =
    stop.address && stop.address !== '' && stop.address !== ' '
      ? `${stop.address} ${stop.city}, ${stop.state} ${stop.zipCode}`
      : addressText

  const stopIsMissed =
    (segment.segmentStatus === 'Completed' && stop.stopStatus === 'New') ||
    stop.stopStatus?.toLowerCase() === 'missed'

  const datesAreValid =
    stop.ServiceStartDateTime &&
    stop.ServiceEndDateTime &&
    !stop.ServiceStartDateTime?.toString().includes('Invalid') &&
    !stop.ServiceEndDateTime?.toString().includes('Invalid')

  const isOnSameDay =
    datesAreValid && stop.ServiceStartDateTime && stop?.ArrivalDate
      ? isSameDay(stop.ServiceStartDateTime, new Date(stop?.ArrivalDate))
      : true
  const timeFormat =
    userConfig && userConfig.Time_Format === '12HR' ? 'h:mm a' : 'HH:mm'
  React.useEffect(() => {
    if (
      (document.getElementById(`${index}-description`)?.clientHeight || 0) > 42
    ) {
      setHasOverflow(true)
    } else {
      setHasOverflow(false)
    }
  }, [index])
  const otherStopsOnOrder = segment.stops.filter(
    s =>
      s.idOrder !== '' &&
      s.idOrder === stop.idOrder &&
      s.idStop !== stop.idStop,
  )

  return (
    <StopCard
      className="stopCard"
      isInProgress={stop.stopStatus === 'Arrived'}
      isInTransit={stop.stopStatus?.toLowerCase() === 'intransit'}
      isCompleted={stop.stopStatus?.toLowerCase() === 'completed'}
      data-cy={'stop-card'}
      data-date={
        datesAreValid &&
        stop.ServiceStartDateTime &&
        isValidDate(stop.ServiceStartDateTime) &&
        format(
          stop.ServiceStartDateTime,
          userConfig?.Date_Format || 'MM/dd/yyyy',
        )
      }
      planStatus={planStatus}
      isSelected={isSelected}
    >
      <StopColumn>
        <StyledChatTeardrop
          isInProgress={stop.stopStatus?.toLowerCase() === 'arrived'}
          isCompleted={stop.stopStatus?.toLowerCase() === 'completed'}
          isMissed={stopIsMissed}
          isInTransit={stop.stopStatus?.toLowerCase() === 'intransit'}
          color={
            stopIsMissed
              ? 'var(--asc-vermilion)'
              : stop.stopStatus?.toLowerCase() === 'intransit'
              ? 'var(--asc-darkolive)'
              : stop.stopStatus?.toLowerCase() === 'arrived' ||
                stop.stopStatus?.toLowerCase() === 'completed'
              ? 'var(--asc-moss)'
              : 'var(--asc-coolgray)'
          }
          weight={'duotone'}
          size={35}
        />
        <StopNumber
          isFinished={
            stop.stopStatus?.toLowerCase() === 'completed' || stopIsMissed
          }
        >
          {index}
        </StopNumber>
        {stop.stopStatus?.toLowerCase() === 'arrived' ||
        stop.stopStatus?.toLowerCase() === 'completed' ? (
          <DashedLineGreen>
            {stop.stopStatus?.toLowerCase() === 'arrived' && (
              <TruckIconHolder>
                <Truck color="var(--asc-moss)" size={25} weight="fill" />
              </TruckIconHolder>
            )}
          </DashedLineGreen>
        ) : (
          <DashedLineGray />
        )}
      </StopColumn>
      <CardColumn>
        <RowWithGap gap="5px">
          <StyledStatusBadge
            lessPadding={true}
            isLate={getIsStopLate(stop)}
            status={stop.stopStatus?.toLocaleLowerCase() || ''}
          />
          {stop.orderServiceType && (
            <ServiceTypeBadge
              serviceType={stop.orderServiceType || undefined}
            />
          )}
        </RowWithGap>
        <Column>
          {stop.orderName && (
            <Tooltip
              trigger={
                <TitleLink
                  onClick={() =>
                    navigate('/routing/orders/' + stop.idOrder, {
                      state: {
                        from: `/routing/routes/${routePlan.idPlan}/${segment.idRouteDispatchDriver}`,
                        route: routePlan,
                      },
                    })
                  }
                  isMissed={stopIsMissed}
                >
                  {stop.orderName}
                </TitleLink>
              }
              tooltip={'Order Name: ' + stop.orderName}
              position={'top center'}
            />
          )}
          {stop.orderDescription && (
            <OrderDescription
              id={`${index}-description`}
              height={expandMessage || !hasOverflow ? 'fit-content' : '43px'}
            >
              {`${stop.orderDescription}${
                hasOverflow && !expandMessage ? '...' : ''
              }`}
            </OrderDescription>
          )}
          {stop.orderDescription && hasOverflow ? (
            !expandMessage ? (
              <ReadMoreOrLess
                onClick={() => {
                  setExpandMessage(true)
                }}
              >
                Read More...
              </ReadMoreOrLess>
            ) : (
              <ReadMoreOrLess
                onClick={() => {
                  setExpandMessage(false)
                }}
              >
                Read Less...
              </ReadMoreOrLess>
            )
          ) : (
            <></>
          )}
        </Column>
        <ExpandableBox isInProgress={stop.stopStatus === 'Arrived'}>
          {isOpen ? (
            <StyledCaretCircleUp
              size={24}
              color="var(--primary)"
              onClick={() => setIsOpen(false)}
            />
          ) : (
            <StyledCaretCircleDown
              size={24}
              color="var(--primary)"
              onClick={() => setIsOpen(true)}
            />
          )}

          <Detail>{stop.companyName}</Detail>

          <CardRowSingle>
            <MapPinLine weight="fill" color={'var(--asc-graphite)'} size={14} />
            <EllipsisText>
              {address &&
              address !== '' &&
              address.replace(/\s/g, '') !== ',' &&
              !address.replace(/\s/g, '').includes('null')
                ? address
                : stop.location.lat
                ? '[' +
                  stop.location.lat.toFixed(4) +
                  ', ' +
                  stop.location.lng.toFixed(4) +
                  ']'
                : ''}
            </EllipsisText>
          </CardRowSingle>

          {isOpen && (
            <>
              {stop.contact && (
                <CardRowSingle>
                  <UserCircle
                    weight="bold"
                    color={'var(--asc-graphite)'}
                    size={14}
                  />
                  <Bold>{stop.contact}</Bold>
                </CardRowSingle>
              )}
              {(stop.phone || stop.email) && (
                <CardRowDouble>
                  {stop.phone && (
                    <>
                      <Phone
                        weight="fill"
                        color={'var(--asc-graphite)'}
                        size={14}
                      />
                      {stop.phone}
                    </>
                  )}

                  {stop.email && (
                    <>
                      <Envelope
                        weight="bold"
                        color={'var(--asc-graphite)'}
                        size={14}
                      />
                      <BoldPrimary>{stop.email}</BoldPrimary>
                    </>
                  )}
                </CardRowDouble>
              )}
              <CardRowSingle>
                {stop.ServiceStartDateTime && stop.ServiceEndDateTime && (
                  <>
                    <CalendarBlank
                      weight="fill"
                      color={'var(--asc-graphite)'}
                      size={14}
                    />
                    {format(
                      stop.ServiceStartDateTime,
                      `${
                        userConfig?.Date_Format || 'MM/dd/yyyy'
                      } ${timeFormat}`,
                    ) +
                      ' - ' +
                      format(
                        stop.ServiceEndDateTime,
                        `${
                          userConfig?.Date_Format || 'MM/dd/yyyy'
                        } ${timeFormat}`,
                      )}{' '}
                  </>
                )}
              </CardRowSingle>
            </>
          )}
        </ExpandableBox>
        <TimeRow gap="10px">
          <TimeColumn>
            <TimeText
              color="var(--asc-dimgray)"
              gap="5px"
              isOnSameDay={isOnSameDay}
            >
              <Tooltip
                tooltip={getTooltip(stop)}
                position={'top center'}
                trigger={<CalendarBlank size={18} />}
              />
              ETA:
              {stop.estimatedTimeArrival
                ? datesAreValid &&
                  isValidDate(new Date(stop.estimatedTimeArrival)) && (
                    <DifferentDayColumn>
                      <div>
                        {(stop.estimatedTimeArrival ||
                          stop?.ServiceStartDateTime) &&
                          'ETA: '}
                        {stop.estimatedTimeArrival ||
                          (stop?.ServiceStartDateTime &&
                            format(
                              stop.estimatedTimeArrival
                                ? new Date(stop.estimatedTimeArrival)
                                : stop?.ServiceStartDateTime,
                              timeFormat,
                            ))}
                      </div>
                    </DifferentDayColumn>
                  )
                : stop.ServiceStartDateTime &&
                  datesAreValid &&
                  isValidDate(stop.ServiceStartDateTime) && (
                    <DifferentDayColumn>
                      <div>
                        {stop?.ServiceStartDateTime &&
                          format(stop?.ServiceStartDateTime, timeFormat)}
                      </div>
                    </DifferentDayColumn>
                  )}
            </TimeText>

            <TimeText color="var(--asc-dimgray)" gap="5px">
              <Tooltip
                tooltip={'Estimated Service Duration'}
                position={'top center'}
                trigger={<Timer size={18} />}
              />

              <div>
                {datesAreValid &&
                  stop?.ServiceEndDateTime &&
                  stop?.ServiceStartDateTime &&
                  getHoursAndMinutes(
                    differenceInMinutes(
                      stop?.ServiceEndDateTime,
                      stop?.ServiceStartDateTime,
                    ) || 0,
                  )}
              </div>
            </TimeText>
          </TimeColumn>
          {stop?.ArrivalDate && stop.ArrivalDate !== '0001-01-01T00:00:00' && (
            <TimeColumn>
              {planStatus.toLowerCase() !== 'planning' && !stopIsMissed && (
                <TimeText
                  color="var(--asc-brown)"
                  gap="5px"
                  isOnSameDay={isOnSameDay}
                >
                  <Tooltip
                    tooltip={'Actual Service Time'}
                    position={'top center'}
                    trigger={<CheckCircle size={18} weight="fill" />}
                  />
                  <DifferentDayColumn>
                    {!isOnSameDay && (
                      <div>
                        {format(
                          new Date(stop?.ArrivalDate),
                          userConfig?.Date_Format || 'MM/dd/YYYY',
                        )}
                      </div>
                    )}
                    <div>
                      {stop?.ArrivalDate && stop?.ArrivalDate.startsWith('2')
                        ? format(parseISO(stop.ArrivalDate), timeFormat)
                        : null}
                      {stop?.DepartureDate &&
                      stop?.DepartureDate.startsWith('2')
                        ? '\u00A0-\u00A0' +
                          format(parseISO(stop.DepartureDate), timeFormat)
                        : null}
                    </div>
                  </DifferentDayColumn>
                </TimeText>
              )}
              {stop?.DepartureDate &&
                stop.DepartureDate !== '0001-01-01T00:00:00' && (
                  <TimeText
                    color="var(--asc-brown)"
                    gap="5px"
                    isOnSameDay={true}
                  >
                    <Tooltip
                      tooltip={'Actual Service Duration'}
                      position={'top center'}
                      trigger={<CheckCircle size={18} weight="fill" />}
                    />
                    <div>
                      {getHoursAndMinutes(
                        differenceInMinutes(
                          new Date(stop?.DepartureDate),
                          new Date(stop?.ArrivalDate),
                        ),
                      )}
                    </div>
                  </TimeText>
                )}
            </TimeColumn>
          )}
          {(stop.stopType === 'Delivery' || stop.stopType === 'Pickup') && (
            <StyledInfoPillContainer>
              <InfoPill
                infoPillBackgroundColor={
                  stop.stopType === 'Pickup'
                    ? 'var(--asc-lime)'
                    : 'var(--asc-gold)'
                }
              >
                <IconContainer>
                  <MapPin color={'#000000'} size={20} weight="fill" />
                </IconContainer>
                <InfoText>{stop.stopType}</InfoText>
              </InfoPill>
            </StyledInfoPillContainer>
          )}
        </TimeRow>
      </CardColumn>
      {planStatus.toLowerCase() !== 'planning' ? (
        <StopCardMenuColumn>
          <StopCardDropdown
            idStop={stop.idStop || ''}
            idSegment={segment.idSegment || ''}
            stopStatus={stop.stopStatus || ''}
            idPlan={idPlan}
            stop={stop}
            isCurrentStop={currentStop === index}
            stopBeforeEndDate={stop.ServiceEndDateTime}
            refetch={refetch}
            planStartDateTime={planStartDateTime}
            routeDetailDispatch={routeDetailDispatch}
            routeHasStarted={segment.stops[0].stopStatus !== 'New'}
            otherStopsOnOrder={otherStopsOnOrder || []}
          />
        </StopCardMenuColumn>
      ) : (
        <Spacer />
      )}
    </StopCard>
  )
}

export default StopPointCard
