import {useStore} from 'common/useStore'
import * as React from 'react'
import styled from 'styled-components/macro'
import {Icon, Image} from 'semantic-ui-react'
import {AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE} from 'common/constants'
import {useMapStore} from 'map/useMapStore'
import {setAssetList} from './recentAssetHelpers'

const Container = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
`

const RecentAssetsContainer = styled.div`
  background-color: white;
  padding: 5px;
  position: absolute;
  top: 56px;
  right: 5px;
  flex-direction: row;
  display: flex;
  max-width: 260px;
  border: solid 2px #b2b2b2;
  border-radius: 5px;
  font-size: 10px;
  color: #353535;
  background-color: #fff;
  font-weight: bold;
  cursor: pointer;
  flex-direction: column;
  &&& img {
    display: block;
    max-width: 50px;
    max-height: 50px;
    width: auto;
    height: auto;
  }
`
const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
`
const ImageContent = styled.div`
  margin: 0 5px;
  padding: 5px 10px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  :hover {
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
  }
`

const AssetName = styled.div`
  width: 60px;
  overflow: hidden;
  color: #252525;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const ImageRow = styled.div`
  overflow-x: auto;
  flex-direction: row;
  display: flex;
  padding: 5px;
`
const RightBorder = styled.div`
  border-right: 1px solid #d4d4d4;
  height: 25px;
  align-self: center;
  display: flex;
`
const RecentAssets = ({visible, setActiveMarker, assetsRef}) => {
  const user = useStore.getState().userConfig
  const activeMarker = useMapStore(state => state.activeMarker)
  const [recentViewAssets, setRecentViewAssets] = React.useState()

  React.useEffect(() => {
    setAssetList({user, assetsRef, setRecentViewAssets})
    // }
  }, [user, assetsRef, activeMarker])

  return (
    <Container visible={visible}>
      <RecentAssetsContainer data-testid="map-toolbar-recentlyviewed-asset">
        <ImageRow>
          {recentViewAssets?.length ? (
            recentViewAssets?.map((item, index) => {
              const marker = {
                idAsset: item.idAsset,
                lat: item.Device.Latitude,
                lng: item.Device.Longitude,
                heading: item.Device.Last_Heading,
                label: item.Asset_Label,
              }
              return (
                <React.Fragment key={index}>
                  <ImageContent
                    key={index}
                    onClick={() => setActiveMarker(marker)}
                  >
                    <RowContent>
                      {item?.Asset_Icon_Full_Path ? (
                        <Image
                          src={
                            AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE +
                            '/' +
                            item?.Asset_Icon_Full_Path
                          }
                          size="small"
                        />
                      ) : (
                        <div style={{height: '25px'}}>
                          <Icon name="marker" size="big" color="red" />
                        </div>
                      )}
                    </RowContent>
                    <AssetName>{item.Asset_Label}</AssetName>
                  </ImageContent>
                  {index + 1 !== recentViewAssets.length ? (
                    <RightBorder />
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              )
            })
          ) : (
            <ImageContent>
              <RowContent>
                {
                  <div style={{height: '25px'}}>
                    <Icon name="marker" size="big" color="red" />
                  </div>
                }
              </RowContent>
              <AssetName>No Recent Assets</AssetName>
            </ImageContent>
          )}
        </ImageRow>
      </RecentAssetsContainer>
    </Container>
  )
}

export default RecentAssets
