import React from 'react'

import {createRoot} from 'react-dom/client'
import GoogleMapReact from 'google-map-react'
import useSocket from 'common/webSockets/hooks/useSocket'

import {useParams, useLocation} from 'react-router'
import {useQueryClient} from '@tanstack/react-query'
import {useMapStore} from './useMapStore'
import {
  dedupeMapUpdates,
  getPointsBounds,
  plotMarkersAndClusters,
  updateMarker,
  createMapOptions,
} from './mapHelpers'
import {MapContainer, MobileInfoWindow, DesktopInfoWindow} from './mapStyles'
import {useWebsocketStore} from 'common/webSockets/useWebsocketStore'
import {BREAKPOINTS, GOOGLE_KEYS} from 'common/constants'
import MapControlDisplay from 'mapControl/MapControlDisplay'

import Gut from 'gut/Gut'
import {initWeatherControl} from './weatherControl'
import {initTrafficControl} from './initTrafficControl'
import AssetListControl from './AssetListControl'
import UnassignedMarker from './UnassignedMarker'
import LocationInfoWindow from 'mapControl/LocationInfoWindow'
import {Asset} from 'common/types/opening1Response'
import {ActiveMarker} from 'common/types/typesModule'
import {FilterAssetsFromListProps} from 'opening/openingHelpers'
// import useWindowSize from 'common/useWindowSize'

type MapProps = {
  filteredAssets: Asset[]
  focusMap: ({
    lat,
    lng,
    map,
  }: {
    lat: number
    lng: number
    map: google.maps.Map
  }) => void
  mapRef: React.MutableRefObject<
    | {
        map: google.maps.Map
      }
    | undefined
  >
  setAndFocusActiveMarker: (marker?: ActiveMarker) => void
  clearMap: () => void
  setAssetFilter: (props: FilterAssetsFromListProps) => void
  assetFilter: {
    label: string
    filteredAssetIDs: number[]
  }
  toggleFollow: () => void
  toggleStreetView: () => void
  clearAssetFilter: () => void
  setLocationsFilter: () => void
}

const Map = ({
  filteredAssets,
  focusMap,
  mapRef,
  setAndFocusActiveMarker,
  clearMap,
  setAssetFilter,
  assetFilter,
  toggleFollow,
  toggleStreetView,
  clearAssetFilter,
  setLocationsFilter,
}: MapProps) => {
  // const {isMobile} = useWindowSize()
  const [initialBoundsFit, setInitialBoundsFit] = React.useState(false)
  const [initialMarkersDrawn, setInitialMarkersDrawn] = React.useState(false)
  const [mapType, setMapType] = React.useState('roadmap')

  const filteredAssetsRef: React.MutableRefObject<Asset[] | undefined> =
    React.useRef()
  const pendingMapUpdates = useWebsocketStore(state => state.pendingMapUpdates)
  const queryClient = useQueryClient()
  const mapInRef = mapRef?.current?.map
  const initialCenter = {lat: 38.09024, lng: -95.712891}
  const initialZoom = 3
  const activeMarker = useMapStore(state => state.activeMarker)
  const activeUnassignedMarker = useMapStore(
    state => state.activeUnassignedMarker,
  )
  const showAssetLabels = useMapStore(state => state.showAssetLabels)
  const showAssetMarkers = useMapStore(state => state.showAssetMarkers)
  const showClusters = useMapStore(state => state.showClusters)
  const clickedLocation = useMapStore(state => state.clickedLocation)
  const location = useLocation()
  const params = useParams()
  const isMobileRef = React.useRef(false)
  useSocket()

  React.useEffect(() => {
    const savedMapType = localStorage.getItem('mapType')
    if (savedMapType) {
      setMapType(savedMapType)
    }
  }, [])

  React.useEffect(() => {
    return function destroyMapStore() {
      useMapStore.setState({
        markers: [],
        clusters: [],
        activeMarker: undefined,
        showClusters: true,
        showAssetLabels: true,
        clickedLocation: null,
      })
    }
  }, [])

  React.useEffect(() => {
    const viewportWidth = window.innerWidth
    if (viewportWidth < BREAKPOINTS.tabletMax) {
      isMobileRef.current = true
    }
  }, [])

  React.useEffect(() => {
    if (activeMarker?.idAsset) {
      window.history.replaceState(null, '', `/map/${activeMarker.idAsset}`)
    }
  }, [activeMarker])

  React.useEffect(() => {
    //initial draw of markers
    //keep in ref so its available to map listeners
    if (
      filteredAssets &&
      initialBoundsFit &&
      showAssetMarkers &&
      mapRef.current
    ) {
      filteredAssetsRef.current = filteredAssets
      plotMarkersAndClusters(filteredAssets, mapRef.current.map)
    }
    return () => {
      filteredAssetsRef.current = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAssets, initialBoundsFit, showAssetMarkers])

  React.useEffect(() => {
    if (!initialBoundsFit && mapInRef && filteredAssets) {
      window.google.maps.event.addListener(mapInRef, 'idle', () => {
        //redraw markers when map moves
        if (filteredAssetsRef.current && showAssetMarkers) {
          plotMarkersAndClusters(filteredAssetsRef.current, mapInRef)
          // window.history.replaceState({}, document.title)
        }
      })
      const newActive = location?.state?.activeMarker
      if (newActive) {
        setAndFocusActiveMarker(newActive)
      } else {
        if (params?.id && filteredAssets) {
          const selected = filteredAssets.find(
            asset => asset.idAsset === Number(params?.id),
          )
          if (selected) {
            const marker = {
              idAsset: selected.idAsset,
              idDevice: selected.Device.idDevice,
              lat: selected.Device.Latitude,
              lng: selected.Device.Longitude,
              heading: selected.Device.Last_Heading,
              label: selected.Asset_Label,
              following: true,
            }
            setTimeout(() => {
              setAndFocusActiveMarker(marker)
            }, 200)
          } else {
            const startingBounds = getPointsBounds(
              filteredAssets,
              window.google.maps,
            )
            if (startingBounds) mapInRef.fitBounds(startingBounds)
          }
        } else if (filteredAssets.length) {
          const startingBounds = getPointsBounds(
            filteredAssets,
            window.google.maps,
          )
          mapInRef.fitBounds(startingBounds)
        }
      }
      if (!filteredAssetsRef.current) {
        filteredAssetsRef.current = filteredAssets
      }
      setInitialBoundsFit(true)
    }
    return () => {
      filteredAssetsRef.current = undefined
      // console.log('removing listener')
      // window.google.maps.event.removeListener(idleListener)
    }
  }, [
    filteredAssets,
    mapInRef,
    location?.state?.activeMarker,
    initialBoundsFit,
    setAndFocusActiveMarker,
    params?.id,
    showAssetMarkers,
  ])

  React.useEffect(() => {
    //redraw based on changes to asset label or cluster toggles
    if (filteredAssetsRef.current && showAssetMarkers) {
      plotMarkersAndClusters(filteredAssetsRef.current, mapInRef)
    }
  }, [mapInRef, showAssetLabels, showAssetMarkers, showClusters])

  React.useEffect(() => {
    //redraw based on changes to asset label or cluster toggles
    if (!showAssetMarkers) {
      plotMarkersAndClusters(null, mapInRef)
    }
  }, [mapInRef, showAssetMarkers])

  const handleMapChange = () => {
    showAssetMarkers &&
      plotMarkersAndClusters(filteredAssetsRef.current, mapInRef)
  }

  type OnMapLoadProps = {
    map: google.maps.Map
    maps: google.maps.MapsLibrary
    data: React.MutableRefObject<Asset[] | undefined>
  }

  const onMapLoad = ({map, maps, data}: OnMapLoadProps) => {
    mapRef.current = {map}
    // Apply saved map type
    map.setMapTypeId(mapType)
    const controlDiv = document.createElement('div')
    const assetListControlDiv = document.createElement('div')
    const weatherControlDiv = document.createElement('div')
    const trafficControlDiv = document.createElement('div')
    const controlRoot = createRoot(controlDiv) // createRoot(container!) if you use TypeScript
    //rendering here so it can be added to map using map.ControlPosition
    controlRoot.render(
      <MapControlDisplay
        map={map}
        assetsRef={data}
        activeMarker={activeMarker}
        setAssetFilter={setAssetFilter}
        setLocationsFilter={setLocationsFilter}
        setActiveMarker={setAndFocusActiveMarker}
        clearAssetFilter={clearAssetFilter}
        clearMap={clearMap}
        // openGrid={openGrid}
      />,
    )
    const assetListRoot = createRoot(assetListControlDiv)
    assetListRoot.render(<AssetListControl />)

    const weatherControl = initWeatherControl(map)
    const weatherControlRoot = createRoot(weatherControlDiv)
    weatherControlRoot.render(weatherControl)
    const trafficControl = initTrafficControl(maps, map)
    const trafficControlRoot = createRoot(trafficControlDiv)
    trafficControlRoot.render(trafficControl)
    //weather control
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      trafficControlDiv,
    )
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      weatherControlDiv,
    )

    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlDiv)
    //asset list control
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(assetListControlDiv)

    map.addListener('maptypeid_changed', () => {
      const currentMapType = map.getMapTypeId()
      setMapType(currentMapType || '')
      localStorage.setItem('mapType', currentMapType || '')
    })

    if (
      filteredAssets &&
      filteredAssets.length > 0 &&
      !initialMarkersDrawn &&
      showAssetMarkers
    ) {
      plotMarkersAndClusters(filteredAssets, map)
      setInitialMarkersDrawn(true)
    }
  }

  React.useEffect(() => {
    //update map for pendingMapUpdates then clear it out
    if (pendingMapUpdates.length > 0) {
      const dedupedUpdates = dedupeMapUpdates(pendingMapUpdates)
      updateMarker(dedupedUpdates)
      if (activeMarker) {
        const activeMarkerUpdate = dedupedUpdates.find(
          update => update.idDevice === activeMarker.idDevice,
        )
        if (activeMarkerUpdate) {
          //update device in cache
          const previousValue = queryClient.getQueryData([
            'assetDetail',
            activeMarker.idAsset,
          ])
          if (previousValue) {
            const newValue = {
              ...previousValue,
              Device: activeMarkerUpdate,
            }
            queryClient.setQueryData(
              ['assetDetail', activeMarker.idAsset],
              newValue,
            )
          }
          if (activeMarker.following && mapRef?.current?.map) {
            focusMap({
              lat: activeMarkerUpdate.Latitude,
              lng: activeMarkerUpdate.Longitude,
              map: mapRef?.current?.map,
            })
          }
        }
      }
      useWebsocketStore.setState({pendingMapUpdates: []})
    }
  }, [activeMarker, focusMap, mapRef, pendingMapUpdates, queryClient])

  return (
    <>
      <MapContainer
        data-cy="map-container"
        id="map-container" //to target classname on activemarker
      >
        {/* {status === 'loading' && <Spinner />} */}
        <GoogleMapReact
          bootstrapURLKeys={GOOGLE_KEYS}
          zoom={initialZoom}
          center={initialCenter}
          onChange={handleMapChange}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) =>
            onMapLoad({map, maps, data: filteredAssetsRef})
          }
        >
          {activeMarker && !isMobileRef.current && mapInRef && (
            <DesktopInfoWindow
              lat={activeMarker.lat}
              lng={activeMarker.lng}
              closeInfoWindow={setAndFocusActiveMarker}
              clearAssetFilter={clearAssetFilter}
              activeMarker={activeMarker}
              map={mapInRef}
              toggleFollow={toggleFollow}
              toggleStreetView={toggleStreetView}
              assets={filteredAssets}
              isMobile={false}
              // openGrid={openGrid}
            />
          )}
          {activeUnassignedMarker && (
            <UnassignedMarker
              device={activeUnassignedMarker}
              lat={activeUnassignedMarker.Latitude}
              lng={activeUnassignedMarker.Longitude}
            />
          )}
          {clickedLocation && mapInRef && (
            <LocationInfoWindow
              location={clickedLocation}
              lat={clickedLocation.coordinates.lat}
              lng={clickedLocation.coordinates.lng}
              map={mapInRef}
            />
          )}
        </GoogleMapReact>
        <Gut
          setAssetFilter={setAssetFilter}
          assetFilter={assetFilter}
          activeMarker={activeMarker}
        />
      </MapContainer>
      {activeMarker && isMobileRef.current && mapInRef && (
        <MobileInfoWindow
          lat={activeMarker.lat}
          lng={activeMarker.lng}
          closeInfoWindow={setAndFocusActiveMarker}
          activeMarker={activeMarker}
          map={mapInRef}
          toggleFollow={toggleFollow}
          toggleStreetView={toggleStreetView}
          assets={filteredAssets}
          isMobile={true}
          // openGrid={openGrid}
        />
      )}
    </>
  )
}

export default Map
