import * as React from 'react'
import {useVirtualizer} from '@tanstack/react-virtual'
// import type {VirtualizerOptions} from '@tanstack/react-virtual'
import styled from 'styled-components/macro'
import AssetCard from './AssetCard'
import Spinner from 'common/components/Spinner'
import AddAssetButton from 'common/components/AddAssetButton'
import {useStore} from 'common/useStore'
import useWindowSize from 'common/useWindowSize'
import {Asset} from 'common/types/opening1Response'
import {ActiveMarker} from 'common/types/typesModule'

const estimateSize = 145

const ListContainer = styled.div`
  overflow-y: auto; // this is necessary for virtual list to work
`
const NoRecords = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  font-weight: bold;
`

type Props = {
  activeMarker: ActiveMarker
  setAndFocusActiveMarker: (marker: ActiveMarker | undefined) => void
  assets: Asset[]
  filteredAssets: Asset[]
  status: 'success' | 'loading' | 'error'
}

const AssetList = ({
  activeMarker,
  setAndFocusActiveMarker,
  assets,
  filteredAssets,
  status,
}: Props) => {
  const setAssetListOpen = useStore(state => state.setAssetListOpen)
  const {isMobile} = useWindowSize()
  const parentRef = React.useRef<HTMLDivElement>(null)
  const activeIDRef = React.useRef<number | undefined>()
  // const scrollingRef = React.useRef<number | undefined>()

  // function easeInOutQuint(t: number) {
  //   return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
  // }

  // const scrollToFn: VirtualizerOptions<any, any>['scrollToFn'] =
  //   React.useCallback(
  //     (offset, canSmooth, instance) => {
  //       const duration = filteredAssets?.length > 2000 ? 0 : 1000
  //       const start = parentRef.current?.scrollTop || 0
  //       const startTime = (scrollingRef.current = Date.now())

  //       const run = () => {
  //         if (scrollingRef.current !== startTime || !parentRef.current) return
  //         const now = Date.now()
  //         const elapsed = now - startTime
  //         const progress = easeInOutQuint(Math.min(elapsed / duration, 1))
  //         const interpolated = start + (offset - start) * progress

  //         if (elapsed < duration) {
  //           elementScroll(interpolated, canSmooth, instance)
  //           requestAnimationFrame(run)
  //         } else {
  //           elementScroll(interpolated, canSmooth, instance)
  //         }
  //       }

  //       requestAnimationFrame(run)
  //     },
  //     [filteredAssets?.length],
  //   )

  const rowVirtualizer = useVirtualizer({
    count: filteredAssets?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => estimateSize,
    // scrollToFn,
    overscan: 5,
  })
  const virtualItems = rowVirtualizer.getVirtualItems()
  // rowVirtualizer.shouldAdjustScrollPositionOnItemSizeChange = () => false
  const changeActiveMarkerFromClick = (e: React.MouseEvent<HTMLElement>) => {
    const id = e.currentTarget.dataset.id
    const asset = assets.find(asset => asset.idAsset === Number(id))
    if (asset) {
      const newActiveMarker: ActiveMarker = {
        idDevice: asset.idDevice,
        idAsset: asset.idAsset,
        label: asset.Asset_Label,
        lat: asset.Device.Latitude,
        lng: asset.Device.Longitude,
        following: true,
        heading: asset.Device.Last_Heading,
      }
      setAndFocusActiveMarker(newActiveMarker)
      if (isMobile) {
        setAssetListOpen(false)
      }
    }
  }

  React.useEffect(() => {
    if (activeMarker?.idAsset && activeMarker.idAsset !== activeIDRef.current) {
      const assetIndex = filteredAssets.findIndex(
        asset => asset.idAsset === Number(activeMarker?.idAsset),
      )
      if (assetIndex || assetIndex === 0) {
        rowVirtualizer.scrollToIndex(assetIndex)
      }
      activeIDRef.current = activeMarker.idAsset
    }
  }, [activeMarker?.idAsset, filteredAssets, rowVirtualizer])

  return (
    <ListContainer ref={parentRef} data-cy="opening-asset-list">
      {status === 'loading' ? (
        <Spinner type="partial" />
      ) : (
        <React.Fragment>
          <div
            className="ListInner"
            style={{
              height: rowVirtualizer.getTotalSize() + 30,
              width: '100%',
              position: 'relative',
            }}
          >
            {virtualItems.map(virtualRow => (
              <div
                key={virtualRow.key}
                ref={rowVirtualizer.measureElement}
                data-index={virtualRow.index}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualRow?.start ?? 0}px)`,
                }}
              >
                <AssetCard
                  activeMarker={activeMarker}
                  assetDetail={filteredAssets[virtualRow.index]}
                  changeActiveMarkerFromClick={changeActiveMarkerFromClick}
                />
              </div>
            ))}
          </div>
          {assets?.length === 0 ? (
            <AddAssetButton />
          ) : filteredAssets?.length === 0 || !filteredAssets ? (
            <NoRecords>No Records Found</NoRecords>
          ) : (
            <></>
          )}
        </React.Fragment>
      )}
    </ListContainer>
  )
}
export default AssetList
