import * as React from 'react'
import {useLocation, Link} from 'react-router'
import styled from 'styled-components/macro'
import {QUERIES} from 'common/constants'
import {CaretRight} from 'phosphor-react'
import {renderToStaticMarkup} from 'react-dom/server'

const Container = styled.div`
  display: none;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 700;
  @media ${QUERIES.laptopAndUp} {
    display: flex;
  }
`

const NoLink = styled.span``

export const Crumb = ({
  href,
  children,
  locationState,
  onClick,
}: {
  href?: string
  children: React.ReactNode
  locationState?: {[key: string]: unknown}
  onClick?: () => void
}) => {
  const location: {state: {filters: unknown}} = useLocation()
  return (
    <CrumbWrapper onClick={onClick}>
      {href ? (
        <CrumbLink
          to={{
            pathname: href,
          }}
          state={{
            filters: location?.state?.filters,
            ...locationState,
          }}
        >
          {children}
        </CrumbLink>
      ) : (
        <NoLink>{children}</NoLink>
      )}
    </CrumbWrapper>
  )
}
const BreadcrumbList = styled.ol`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
`

const reactSvgComponentToMarkupString = () =>
  `data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(React.createElement(CaretRight, {})),
  )}`

const CrumbWrapper = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  --spacing: 10px;
  &:not(:first-of-type) {
    margin-left: var(--spacing);
    &:before {
      color: var(--asc-sonicsilver);
      content: ${() => `url(${reactSvgComponentToMarkupString()})`};
      margin-right: var(--spacing);
      margin-top: 5px;
    }
  }
  :hover {
    text-decoration: ${props => props.onClick && 'underline'};
    cursor: ${props => props.onClick && 'pointer'};
  }
`
const CrumbLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: revert;
    color: var(--primary);
  }
`

const BreadCrumbs = ({children}: {children: React.ReactNode}) => {
  return (
    <Container>
      <nav aria-label="Breadcrumb">
        <BreadcrumbList>{children}</BreadcrumbList>
      </nav>
    </Container>
  )
}
export default BreadCrumbs
