import * as React from 'react'
import {Dropdown} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import Swal from 'sweetalert2'
import {useStore} from 'common/useStore'
import {VERSION} from 'common/constants'
import {
  Question,
  ChatCircleText,
  WarningCircle,
  CaretUp,
  CaretDown,
} from 'phosphor-react'
import ContactSupport from './ContactSupport'
import FeedBack from 'feedback/Feedback'
import {ascendColors} from 'common/GlobalStyles'

const StyledDropdown = styled(Dropdown)<{$fontOnSecondary: string}>`
  &&& {
    font-family: 'Open Sans';
    div.menu {
      top: 30px;
      left: -72px;
      border: 1px solid var(--asc-graphite);
      padding: 5px;
      border-radius: 6px;
    }

    .selected.item {
      background: white;
      &:hover {
        font-weight: 700;
      }
    }
    div.item {
      padding: 10px;
      font-weight: 700;
      background-color: white;
      &:hover {
        background-color: var(--secondary);
        color: ${p => p.$fontOnSecondary};
        font-weight: 700;
      }
    }
    i.dropdown {
      display: none;
    }
    div.menu::before {
      content: '';
      display: block;
      position: absolute;
      left: 105px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: var(--asc-graphite);
    }
    div.menu::after {
      content: '';
      display: block;
      position: absolute;
      left: 106px;
      bottom: 99%;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-bottom-color: white;
      bottom: calc(100% - 0.5px);
    }
  }
`

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin: 0 5px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  span {
    font-weight: bold;
    font-size: 13px;
    padding-left: 5px;
  }
  .item {
    svg {
      display: inline;
      margin-right: 5px;
    }
  }
`

const StyledDropdownItem = styled(Dropdown.Item)`
  &&& {
    display: flex !important;
  }
`

const HelpMenuDropdown = () => {
  const [openModal, setOpenModal] = React.useState<string | null>(null)
  const [menuOpen, setMenuOpen] = React.useState(false)
  const closeModal = () => setOpenModal(null)
  const userConfig = useStore.getState().userConfig
  return (
    <React.Fragment>
      <Content>
        <StyledDropdown
          floating
          text={``}
          direction="right"
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
          icon={
            <Trigger data-testid="topbar-help">
              <Question size={23} weight={'fill'} />
              <span>Support</span>
              {menuOpen ? (
                <CaretUp size={12} weight="fill" />
              ) : (
                <CaretDown size={12} weight="fill" />
              )}
            </Trigger>
          }
          closeOnBlur={true}
          $fontOnSecondary={ascendColors.fontOnSecondary}
        >
          <Dropdown.Menu>
            <StyledDropdownItem
              icon={<Question size={17} weight={'regular'} />}
              text="Contact Support"
              onClick={() => {
                setOpenModal('contactSupport')
                setMenuOpen(false)
              }}
              data-testid="help-contactsupport"
            />
            {/* <StyledDropdownItem
              icon={<Book size={17} weight={'fill'} />}
              text="Help"
              data-testid="navbar-help-button"
            /> */}
            <StyledDropdownItem
              icon={<ChatCircleText size={17} weight={'fill'} />}
              text="Send Feedback"
              onClick={() => {
                setOpenModal('feedback')
                setMenuOpen(false)
              }}
              data-testid="help-sendfeedback"
            />
            <StyledDropdownItem
              icon={<WarningCircle size={17} weight={'fill'} />}
              data-testid="help-about"
              text="About"
              onClick={() => {
                Swal.fire({
                  title: '<strong>Geoforce Ascend</strong>',
                  icon: 'success',
                  html: `<div style="color:#fff; font-size: 8px; margin-top: -10px; ">
                  The Ascenders: SUBBY1, Dr Dan, Dark Crystal, Dallas Cowboy</div>
                  <div>
                  <div>Version ${VERSION}</div>
                  <div>${userConfig?.WebServer}</div>`,
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK',
                  confirmButtonColor: 'var(--tertiary)',
                })
                // setShowDialog(true)
                setMenuOpen(false)
              }}
            />
          </Dropdown.Menu>
        </StyledDropdown>
      </Content>
      {openModal === 'contactSupport' && (
        <ContactSupport onDismiss={closeModal} />
      )}
      {openModal === 'feedback' && <FeedBack onDismiss={closeModal} />}
    </React.Fragment>
  )
}
export default HelpMenuDropdown
