import React from 'react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
//import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import Axios from 'axios'
import {APP_API_URI} from 'common/constants'
import GlobalStyles from './common/GlobalStyles'
import registerFaIcons from './registerFaIcons'
import 'semantic-ui-css/semantic.min.css'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'toastr/build/toastr.min.css'
import './App.css'
import Main from './Main'

const staleTimeInMs = 1000 * 3 //If query has been fetched within the last 3 seconds don't refetch

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      staleTime: staleTimeInMs,
    },
  },
})

queryClient.setQueryDefaults(['token'], {staleTime: Infinity})

registerFaIcons()

const App = () => {
  // Axios.create()
  Axios.defaults.baseURL = APP_API_URI
  Axios.defaults.headers.common['Content-Type'] =
    'application/json;charset=utf-8'
  Axios.defaults.headers.common['Cross-Origin-Embedder-Policy'] = 'require-corp'
  Axios.defaults.headers.common['Cross-Origin-Opener-Policy'] = 'same-origin'

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <Main />
      {/* <ReactQueryDevtools
        initialIsOpen={process.env.NODE_ENV === 'development'}
      /> */}
    </QueryClientProvider>
  )
}

export default App
