import React from 'react'
import {NoRecordsRow, RouteTimeTableContainer} from './routePlanTimeTableStyles'
import {
  RoutePlanWithColor,
  SegmentWithColor,
} from 'routingAndDispatch/Routes/PlanReview/PlanReview'
import RouteTimeTableRow from './RouteTimeTableRow'
import {getSegmentRows, getTimeRange} from './routePlanTimeTableHelpers'
import {useNavigate} from 'react-router'
import {compareAsc} from 'date-fns'
import RouteTimeTableHeaders from './RouteTimeTableHeaders'
import {useRouteDetailStore} from 'routingAndDispatch/Routes/Route Detail/hooks/useRouteDetailStore'
import {
  getSegmentLatLngs,
  removeAllRoutes,
  renderRoutes,
} from 'routingAndDispatch/Routes/Route Detail/routeDetailHelpers'

type RouteTimeTableProps = {
  routeData: RoutePlanWithColor
  rowClick?: (idSegment: string) => void
  selectedSegments?: string[]
  isRouteDetail: boolean
  initSort?: {
    accessor: string
    type: 'asc' | 'des' | ''
  }
  showNowTimeLine?: boolean
  dateToView?: Date
  driverSelectAll?: () => SegmentWithColor[]
}

const RouteTimeTable: React.FC<RouteTimeTableProps> = ({
  routeData,
  rowClick,
  selectedSegments,
  isRouteDetail,
  showNowTimeLine = false,
  dateToView = undefined,
  initSort,
  driverSelectAll,
}) => {
  const navigate = useNavigate()
  //if planning, driver, vehicle, stops, distance, duration, chart
  //if not, planning, driver, vehicle, status, stops, buttons, chart
  const {earliestTime, latestTime} = getTimeRange(
    routeData,
    dateToView || routeData.planStartDateTime,
  )
  const [drawTimeLineOnChart, setDrawTimeLineOnChart] = React.useState(false)

  const [sortBy, setSortBy] = React.useState<{
    accessor: string
    type: 'asc' | 'des' | ''
  }>(initSort || {accessor: '', type: 'asc'})

  const rows = React.useMemo(() => {
    return getSegmentRows({
      routeData,
      navigate,
      selectedSegments,
      isRouteDetail,
      earliestTime,
      latestTime,
      sortBy,
    })
  }, [
    earliestTime,
    isRouteDetail,
    latestTime,
    navigate,
    routeData,
    selectedSegments,
    sortBy,
  ])

  const selectAllClick = () => {
    let bounds: null | google.maps.LatLngBounds =
      new window.google.maps.LatLngBounds()
    const segmentsToRender = driverSelectAll && driverSelectAll()
    const map = useRouteDetailStore.getState().map
    if (segmentsToRender && segmentsToRender.length) {
      segmentsToRender.forEach(segment => {
        const color = segment.color ? segment.color : '#5f3b0c'
        const points = getSegmentLatLngs(segment)
        bounds = renderRoutes({
          id: segment.idRouteDispatchDriver,
          points,
          color,
          bounds,
          type: 'add',
        })
      })
      if (map && bounds) map.fitBounds(bounds)
    } else {
      removeAllRoutes()
    }
  }

  React.useEffect(() => {
    const chartTimeNowLine = document.getElementById('time-now-line')

    const nowTime = new Date()
    const planIsActiveNow =
      compareAsc(nowTime, routeData.planStartDateTime) >= 0 &&
      compareAsc(nowTime, routeData.planEndDateTime) <= 0
    if (
      chartTimeNowLine &&
      showNowTimeLine &&
      drawTimeLineOnChart &&
      planIsActiveNow
    ) {
      const container = document.getElementsByClassName(
        'timeTable',
      )[0] as HTMLElement

      if (chartTimeNowLine && container) {
        const rows = document.getElementsByClassName('row')
        let combinedHeight = 10

        for (let i = 0; i < rows.length; i++) {
          const row = rows[i] as HTMLElement // Cast the element to HTMLElement
          combinedHeight += row.offsetHeight
        }

        //find where the d3 time-now-line is
        const updateDivPosition = () => {
          const rect = chartTimeNowLine.getBoundingClientRect()
          const x = !isRouteDetail ? rect.left - 235 : rect.left

          div.style.left = `${x}px`
        }

        //creates outer div time line where d3 line is
        const rect = chartTimeNowLine.getBoundingClientRect()
        const x = !isRouteDetail ? rect.left - 235 : rect.left
        const div = document.createElement('div')
        div.style.position = 'absolute'
        div.style.width = '2px'
        div.style.height = `${combinedHeight - 10}px` // initial height
        div.style.backgroundColor = 'var(--asc-brown)'
        div.style.zIndex = '6'
        div.style.display = 'block'
        div.style.left = `${x}px`
        updateDivPosition()
        container.appendChild(div)
        container.addEventListener('scroll', updateDivPosition)

        //time bubble
        const text = document.createElement('div')
        text.style.position = 'absolute'
        text.style.width = '50px'
        text.style.height = 'fit-content'
        text.style.left = '-25px'
        text.style.textAlign = 'center'
        text.style.fontSize = '14px'
        text.style.backgroundColor = 'var(--asc-tan)'
        text.style.color = 'var(--asc-brown)'
        text.style.borderRadius = '10px'
        text.style.fontWeight = 'bold'
        text.textContent = chartTimeNowLine.innerHTML

        // add a triangle pointing down
        text.style.position = 'relative'
        text.style.overflow = 'hidden'
        text.style.marginBottom = '-10px' // to make space for the triangle
        const triangle = document.createElement('div')
        triangle.style.position = 'absolute'
        triangle.style.top = '15px'
        triangle.style.left = '50%'
        triangle.style.transform = 'translateX(-50%)'
        triangle.style.width = '0'
        triangle.style.height = '0'
        triangle.style.borderLeft = '10px solid transparent'
        triangle.style.borderRight = '10px solid transparent'
        triangle.style.borderTop = '10px solid var(--asc-tan)'
        div.appendChild(triangle)
        div.appendChild(text)

        // update height when container element resizes
        const observer = new ResizeObserver(entries => {
          for (const entry of entries) {
            if (entry.target === container) {
              div.style.height = `${combinedHeight - 10}px`
              updateDivPosition()
            }
          }
        })
        observer.observe(container)
        return () => {
          observer.disconnect()
          container.removeEventListener('scroll', updateDivPosition)
          container.removeChild(div)
        }
      }
    }
  }, [
    routeData.planEndDateTime,
    routeData.planStartDateTime,
    drawTimeLineOnChart,
    showNowTimeLine,
    isRouteDetail,
  ])

  React.useEffect(() => {
    setDrawTimeLineOnChart(true)
  }, [])

  return (
    <RouteTimeTableContainer
      className="timeTable"
      data-cy="route-plan-timetable"
    >
      <RouteTimeTableHeaders
        isRouteDetail={isRouteDetail}
        routeData={routeData}
        sortBy={sortBy}
        headerClick={sortByHeader => {
          setSortBy(sortByHeader)
        }}
        earliestTime={earliestTime}
        latestTime={latestTime}
        selectAllClick={selectAllClick}
        selectedSegments={selectedSegments}
      />
      {rows.map((row, index) => {
        const idRouteDispatchDriver =
          routeData?.segments[index]?.idRouteDispatchDriver

        return (
          <RouteTimeTableRow
            key={index}
            isPlanning={routeData.planStatus.toLowerCase() === 'planning'}
            isRouteDetail={isRouteDetail}
            rowData={row}
            idRouteDispatchDriver={idRouteDispatchDriver}
            rowClick={() => {
              rowClick && rowClick(idRouteDispatchDriver || '')
            }}
          />
        )
      })}
      {routeData.segments.length === 0 && (
        <div>
          <NoRecordsRow data-cy="noRecords">No records found</NoRecordsRow>
        </div>
      )}
    </RouteTimeTableContainer>
  )
}

export default RouteTimeTable
