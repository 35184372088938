import * as React from 'react'
import {Icon} from 'semantic-ui-react'
import Swal from 'sweetalert2'

// export const trimFileName = fileName => {
//   var leftRightStrings = fileName.split('.')
//   //file name
//   var fName = leftRightStrings[0]
//   //file extension
//   var fExtention = leftRightStrings[1]
//   var lengthFname = fName.length
//   //if file name without extension contains more than 15 characters
//   if (lengthFname > 15) {
//     return fName.substr(0, 10) + '...' + fName.substr(-5) + '.' + fExtention
//   }
//   return fileName
// }
export const trimUserName = name => {
  var lengthName = name.length
  //if name contains more than 15 characters
  if (lengthName > 20) {
    return name.substr(0, 15) + '...' + name.substr(-5)
  }
  return name
}

export const addThumbURL = (URL, Type) => {
  let thumbURL = URL
  if (Type && Type.startsWith('image')) {
    const qmPosition = URL.indexOf('?')
    const value = '-thumb'
    thumbURL = URL.substr(0, qmPosition) + value + URL.substr(qmPosition)
  }
  return thumbURL
}
/**
 *
 * @param {string} filetype -- from file input
 *
 * returns string -- font-awesome icon
 */
export const getIconDoctype = filetype => {
  let defaultIconType = <Icon name="file outline" size="huge" />
  switch (filetype) {
    case 'application/pdf':
      defaultIconType = <Icon name="file pdf outline" size="huge" color="red" />
      break
    case 'video/mp4':
    case 'video/avi':
    case 'video/msvideo':
    case 'video/avs-video':
    case 'video/mpeg':
    case 'video/quicktime':
      defaultIconType = <Icon name="file video outline" size="huge" />
      break
    case 'text/plain':
      defaultIconType = <Icon name="file text outline" size="huge" />
      break
    case 'audio/mpeg':
    case 'audio/ogg':
    case 'audio/mpeg3':
    case 'audio/x-mpeg-3':
      defaultIconType = <Icon name="file audio outline" size="huge" />
      break
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      defaultIconType = <Icon name="file word outline" size="huge" />
      break
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
    case 'application/vnd.ms-excel.template.macroEnabled.12':
    case 'application/vnd.ms-excel.addin.macroEnabled.12':
    case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      defaultIconType = <Icon name="file excel outline" size="huge" />
      break
    case 'application/vnd.ms-powerpoint':
      defaultIconType = <Icon name="file powerpoint outline" size="huge" />
      break
  }
  return defaultIconType
}
export const allowedFileTypes = [
  '.txt',
  '.doc',
  '.docx',
  '.docm',
  '.xls',
  '.xlsx',
  '.xlsm',
  '.xlsb',
  '.pdf',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.ms-word.document.macroEnabled.12',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/jpg',
  'text/plain',
  'jpeg',
  'jpg',
  'png',
  'gif',
  'bmp',
  'pjpeg',
]

const isFileAllowed = filetype => allowedFileTypes.indexOf(filetype) !== -1

export const isFileImage = filetype => {
  const imageTypes = [
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/jpg',
    'jpeg',
    'jpg',
    'png',
    'gif',
    'bmp',
    'pjpeg',
  ]
  const match = imageTypes.find(element => {
    if (filetype.toLowerCase().includes(element.toLowerCase())) {
      return true
    }
    return false
  })
  return !!match
}

/**
 * handle remove file
 */
export const handleRemoveFile = (file, dispatch, deleteFile, event) => {
  event && event.stopPropagation()
  Swal.fire({
    icon: 'question',
    showCancelButton: true,
    title: `Are you sure you want to delete this file:\n ${file.FriendlyName}?`,
  }).then(({isConfirmed}) => {
    if (isConfirmed) {
      dispatch({type: 'Uploading', data: true})
      //remove ref path from the document db
      deleteFile([file.idDocument]).then(() => {
        dispatch({type: 'DeleteFile', data: file.idDocument})
        dispatch({type: 'Uploading', data: false})
      })
    }
  })
}
export const droppedFiles = (event, dispatch, postFileHandler, id) => {
  const dt = event.dataTransfer
  const files = dt.files
  const allowedFiles = Object.fromEntries(
    Object.entries(files).filter(([key]) => {
      return isFileAllowed(files[key].type)
    }),
  )
  readFiles(allowedFiles, dispatch, postFileHandler, id)
}

export const handleFilesChanged = (event, dispatch, postFileHandler, id) => {
  const {files} = event.target
  const allowedFiles = Object.fromEntries(
    Object.entries(files).filter(([key]) => {
      return isFileAllowed(files[key].type)
    }),
  )
  readFiles(allowedFiles, dispatch, postFileHandler, id)
}

/**
 * helper function to check files type (from file input)
 * convert a file to base64 image
 * @param {image file to resize} file
 * @param {string} uniqueID - to allow multiple uploads on a page. Defaults to ""
 */
const readFileInformation = (file, uniqueID) => {
  //const imageUpload = null;
  const reader = new FileReader()
  //const processedFile = null;
  reader.readAsDataURL(file)
  return new Promise((resolve, reject) => {
    reader.onload = function () {
      //push base 64 encoded file to a temp object
      const extractedFileInfo = reader?.result?.split(',')
      let type = ''
      const attemptTypeLookup = extractedFileInfo[0].match(
        /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+)/,
      )
      if (attemptTypeLookup && attemptTypeLookup.length > 1) {
        //type was found - sometimes doesn't happen if browser doesn't recognize file type
        type = attemptTypeLookup[1]
      }
      //sometimes browser doesn't get type
      const tempFile = {
        fileName: file.name,
        type: type,
        file: extractedFileInfo[1],
        uniqueID,
        Ancillary: generateGuid(),
      }
      resolve(tempFile)
    }
    reader.onerror = function () {
      Swal.fire('Error: ')
      reject(console.error('Error processing input document'))
    }
  })
}
/**
 *
 * @param {image files} files
 * @param {path to save to DocUpload*} path
 * @returns {{idDocument: String, Ancillary: String, FriendlyName: String, Type: String, URL: String}[]} processedFiles
 */

const readFiles = async (files, dispatch, postFileHandler, id) => {
  //store path when reading files and userInformation

  Object.keys(files).length && dispatch({type: 'Uploading', data: true, id})
  const goodFiles = []
  let filesRemoved = false
  const processedFiles = []

  for (let i = 0; i < Object.keys(files).length; i++) {
    //check each file dropped to see if over size limit
    if (files[Object.keys(files)[i]].size > 10000000) {
      filesRemoved = true
    } else {
      goodFiles.push(files[Object.keys(files)[i]])
    }
  }
  if (!Object.keys(files).length) {
    Swal.fire('File type is not supported or no files selected')
    dispatch({type: 'FileError'})
  }
  if (filesRemoved && Object.keys(files).length) {
    Swal.fire('File size must be less than 10MB')
    dispatch({type: 'FileError'})
  }

  if (goodFiles.length > 0) {
    //process all the good files
    for (const file of goodFiles) {
      const processedFile = await readFileInformation(file, Date.now())

      processedFiles.push(processedFile)
    }
  }

  const fileReturned = await postFileHandler(processedFiles)
  const mergedFilesInformation = []
  if (fileReturned) {
    fileReturned.forEach((file, index) => {
      const {URL, idDocument} = file
      const {fileName: FriendlyName, type, file: base64} = processedFiles[index]
      mergedFilesInformation.push({
        FriendlyName,
        Type: type,
        URL,
        idDocument,
        id,
        base64,
      })
    })

    dispatch({type: 'AddNewFiles', data: mergedFilesInformation})
  } else {
    console.error('No file returned')
  }
}
export const generateGuid = () => {
  function S4() {
    return parseInt((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-4' +
    S4().substr(0, 3) +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  ).toLowerCase()
}
export const handlePreventDefault = e => {
  e.preventDefault()
  e.stopPropagation()
}
export const handleDrop = (event, dispatch, postFileHandler, id) => {
  event.preventDefault()
  event.stopPropagation()
  droppedFiles(event, dispatch, postFileHandler, id)
}

// export const unassociateFiles = async ({fileState, deleteFile}) => {
//   const unassociateNewFiles = fileState.files.filter(file => file.newFile)
//   if (unassociateNewFiles?.length) {
//     const documentIds = unassociateNewFiles.map(file => file.idDocument)
//     if (documentIds?.length) {
//       await deleteFile(documentIds)
//     }
//   }
// }
export const associateFiles = async ({id, fileState, putFiles, context}) => {
  let documentIds = []
  for (const file of fileState.files) {
    if (file.newFile) {
      documentIds.push(file.idDocument)
    }
  }
  if (documentIds?.length) {
    await putFiles({id: parseInt(id), context, documentIds})
  }
}
