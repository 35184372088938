import * as React from 'react'
import styled from 'styled-components/macro'
import {Icon, Card, Divider, Label} from 'semantic-ui-react'
import useCustomerSupport from './hooks/useCustomerSupport'
import Modal from 'common/Modal'

const Container = styled.div`
  display: flex;
`
const ModalBody = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`
const DealerInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`
const ContactInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  && a {
    margin: 5px 0;
  }
`
const Information = () => {
  return (
    <ContactInfo>
      <div>
        <Icon name="mail" />
        support@geoforce.com
      </div>
      <div>
        <Icon name="phone" />
        Phone: 855-287-7353
      </div>
    </ContactInfo>
  )
}
const DealerInformation = ({data}) => {
  return (
    <ContactInfo>
      {(data?.DealerUsers[0]?.First_Name ||
        data?.DealerUsers[0]?.Last_Name) && (
        <Label as="div" size="big">
          <Icon name="user" />
          {`${data?.DealerUsers[0]?.First_Name} ${data?.DealerUsers[0]?.Last_Name}`}
        </Label>
      )}
      <div>
        <Icon name="mail" />
        Email:{' '}
        {data?.SupportEmailAddress
          ? data?.SupportEmailAddress
          : 'support@geoforce.com'}
      </div>
      <div>
        <Icon name="phone" />
        Phone: {data?.SupportPhoneNumber}
      </div>
    </ContactInfo>
  )
}
const ContactSupport = ({onDismiss}) => {
  const {data} = useCustomerSupport()

  return (
    <Container>
      <Modal
        showDialog={!(status === 'loading')}
        title={'Customer Support'}
        width={'600px'}
        renderFooter={false}
        onDismiss={onDismiss}
        submitButtonText="OK"
      >
        <ModalBody>
          {data && (
            <DealerInfo>
              <Label size="big">Dealer Name: {data?.Dealer_Name} </Label>
            </DealerInfo>
          )}
          <Card fluid>
            <Card.Content>
              <Card.Header>
                {data
                  ? 'Contact Information'
                  : 'Geoforce Ascend Customer Support'}
              </Card.Header>
              <Divider />
              <Card.Description>
                {data && <DealerInformation data={data} />}
                {!data && <Information />}
              </Card.Description>
            </Card.Content>
          </Card>
        </ModalBody>
      </Modal>
    </Container>
  )
}
export default ContactSupport
