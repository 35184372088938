import React, {useRef, useReducer, useEffect} from 'react'
import styled from 'styled-components/macro'
import {useLocation} from 'react-router'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  reducer,
  initialState,
  fetchLogo,
  handleImageUpload,
  saveLogo,
} from './brandingHelpers'
import {CustomerType} from 'management/hooks/useCustomers'

type Props = {
  selected: CustomerType | null
}

const BrandingContainer = styled.div`
  display: flex;
  flex-direction: row;

  z-index: 1;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
`

const CurrentBrandingContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const BrandingSelectedContainer = styled.div`
  display: flex;
  justify-content: center;
`

const NewBrandingContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dedede;
  padding: 10px;
  border-radius: 0px 5px 5px 5px;
  width: 300px;
`

const Button = styled.button`
  font-size: calc(14 / 14 * 1rem);
  background-color: ${(p: {color?: string; textColor?: string}) =>
    p.color || 'rgba(0,0,0,0.1)'};
  color: ${p => (p.textColor ? p.textColor : 'black')};
  padding: 0.5em 1em;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: border 0.2s ease-in-out;
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  button {
    width: 100%;
  }
  button + button {
    margin-left: 10px;
  }
`

const ImageContainer = styled.div`
  padding: 2em;
  display: flex;
  justify-content: center;
`

const ImagePreview = styled.img`
  max-width: 250px;
  max-height: 50px;
`

const Header = styled.h1`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: calc(17 / 14 * 1rem);
`

const LogoHeader = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: calc(14 / 14 * 1rem);
  }
`

const FileUploadInput = styled.input`
  display: none;
`

const NoRecordsRow = styled.div`
  font-size: calc(17 / 14 * 1rem);
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Container = styled.div`
  border-top: 4px solid #dedede;
`

const Branding = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {selected} = props
  const location = useLocation()
  const {pathname} = location
  const idCustomer = selected?.idcustomer ? selected.idcustomer : null
  const idDealer =
    pathname.startsWith('/management/dealers') && selected && selected?.idDealer
      ? selected?.idDealer
      : null

  useEffect(() => {
    if (idCustomer) {
      fetchLogo(idCustomer, 'customer', dispatch)
    } else if (idDealer) {
      fetchLogo(idDealer, 'dealer', dispatch)
    }
  }, [state.logoFetched, idCustomer, idDealer])

  const inputEl = useRef<HTMLInputElement>(null)
  const onButtonClick = () => {
    if (inputEl && inputEl.current) {
      inputEl.current.value = ''
      inputEl.current.click()
    }
  }

  const cancelLogo = () => {
    dispatch({type: 'cancelLogo'})
  }
  return (
    <Container>
      <BrandingContainer>
        {selected ? (
          <BrandingSelectedContainer>
            <CurrentBrandingContainer>
              <Header>{selected.Customer_Name || selected.Dealer_Name}</Header>
              <LogoHeader>
                <h2>Logo</h2>
                &nbsp;&nbsp;
                <span>(Logo is sized to 250px X 50px)</span>
              </LogoHeader>
              {state.logoFile ? (
                <>
                  <ImageContainer>
                    <ImagePreview src={state.logoFile}></ImagePreview>
                  </ImageContainer>
                </>
              ) : null}
            </CurrentBrandingContainer>
            <NewBrandingContainer>
              <Button onClick={onButtonClick}>
                <FontAwesomeIcon icon="upload" />
                <span>&nbsp;Upload&nbsp;Logo</span>
              </Button>
              <FileUploadInput
                ref={inputEl}
                onChange={e => handleImageUpload(e, dispatch)}
                type="file"
                accept="image/*"
              />
              {state.newLogoFile ? (
                <>
                  <ImageContainer>
                    <ImagePreview src={state.newLogoFileURL}></ImagePreview>
                  </ImageContainer>
                  <ButtonRow>
                    <Button color={'##6c757d'} onClick={cancelLogo}>
                      Cancel
                    </Button>
                    <Button
                      textColor={'white'}
                      color={'#007bff'}
                      onClick={() =>
                        saveLogo(state, idCustomer, idDealer, dispatch)
                      }
                    >
                      Save
                    </Button>
                  </ButtonRow>
                </>
              ) : null}
            </NewBrandingContainer>
          </BrandingSelectedContainer>
        ) : (
          <NoRecordsRow>Select a customer above to see branding</NoRecordsRow>
        )}
      </BrandingContainer>
    </Container>
  )
}
export default Branding
