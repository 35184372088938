import * as React from 'react'
import {NavigateFunction, useNavigate} from 'react-router'
import {CellContext, ColumnDef} from '@tanstack/react-table'
import ResizableTable from 'common/tables/ResizableTable/ResizableTable'
import {ListContainer, BreadCrumbs, Crumb} from 'common/components'
import TableActionButtons from 'common/tables/TableActionButtons'
import {useStore} from 'common/useStore'
import CameraEventRequestVideo from './CameraEventRequestVideo'
import {AssetImage, AssetImageDiv} from 'assets/AssetList'
import {AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE} from 'common/constants'
import {convertToUsersDateAndTimeFormat} from 'common/helpersDateTime'
import useCameraList, {CameraListType} from './hooks/useCameraList'
import {openWindowTab} from 'common/helpers'
import StatusBadge from './StatusBadge'
import CameraUsageDetail from './CameraUsageDetail'

export type CustomDropDownItemType = {
  text: string
  onClick: () => void
  testId: string
}

const ActionCell = (
  instance: CellContext<CameraListType, unknown>,
  setRequestedCamera: React.Dispatch<
    React.SetStateAction<CameraListType | null>
  >,
  setShowCameraUsage: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
) => {
  const {original} = instance.row
  const [customDropdownItems, setCustomDropdownItems] = React.useState<
    CustomDropDownItemType[]
  >([])

  React.useEffect(() => {
    const initialDropDownItems = [
      {
        text: 'Camera Events',
        onClick: () => {
          navigate('/cameras/cameraevents', {
            state: {requestedCamera: original.Asset_Label},
          })
        },
        testId: 'view-camera-events',
      },
      {
        text: 'Asset Dashboard',
        onClick: () => {
          openWindowTab(
            'asset_dashboard',
            `/asset-dashboard/${original.idAsset}?start=camera`,
          )
        },
        testId: 'camera-list-asset-dashboard-button',
      },
      {
        text: 'View History Trail',
        onClick: () => {
          const dateOnly = original.Last_Location_DateTime_Local.substring(
            0,
            10,
          )
          navigate(
            `/historytrail/${original.idAsset}?startDate=${
              dateOnly + 'T00:00:00'
            }&endDate=${dateOnly + 'T23:59:59'}`,
          )
        },
        testId: 'camera-history-trail-button',
      },
    ]
    const customDropdownItems = initialDropDownItems
    if (original.idDevice_Type === 35) {
      customDropdownItems.push(
        {
          text: 'Camera Settings',
          onClick: () => {
            navigate(`/cameras/settings/${original.idAsset}`)
          },
          testId: 'camera-history-trail-button',
        },
        {
          text: 'View Camera Usage',
          onClick: () => {
            setRequestedCamera(original)
            setShowCameraUsage(true)
          },
          testId: 'camera-event-view-camera-usage-button',
        },
      )
    }
    if (
      original.CameraStatus !== 'offline' &&
      original.CameraStatus !== 'Not Found'
    ) {
      customDropdownItems.push({
        text: 'Request Video',
        onClick: () => {
          setRequestedCamera(original)
        },
        testId: 'view-camera-events',
      })
    }
    setCustomDropdownItems(customDropdownItems)
  }, [
    navigate,
    original,
    original.CameraStatus,
    setRequestedCamera,
    setShowCameraUsage,
  ])
  return (
    <React.Fragment>
      <TableActionButtons
        editAction={() =>
          navigate(`/assets/${original.idAsset}`, {state: {from: 'cameras'}})
        }
        customDropdownItems={customDropdownItems}
      />
    </React.Fragment>
  )
}

const AssetImageCell = (instance: CellContext<CameraListType, unknown>) => {
  const value = instance.getValue()
  if (value) {
    return (
      <AssetImageDiv>
        <AssetImage src={`${AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE}/${value}`} />
      </AssetImageDiv>
    )
  }
  return null
}

const CamerasList = () => {
  // const location = useLocation()
  const {data: cameras, status} = useCameraList()
  const [requestedCamera, setRequestedCamera] =
    React.useState<CameraListType | null>(null)
  const [showCameraUsage, setShowCameraUsage] = React.useState(false)
  const setBreadcrumbs = useStore(state => state.setBreadcrumbs)
  const navigate = useNavigate()

  // const initialFilters = location?.state?.filters
  //   ? location.state.filters
  //   : {filters: [], globalFilter: ''}

  React.useEffect(() => {
    setBreadcrumbs(
      <BreadCrumbs>
        <Crumb href="/map">Home</Crumb>
        <Crumb>Cameras</Crumb>
      </BreadCrumbs>,
    )
  }, [setBreadcrumbs])

  const columns: ColumnDef<CameraListType>[] = [
    {
      header: 'Name',
      accessorKey: 'Asset_Label',
      id: 'name',
      size: 300,
    },
    {
      header: 'Image',
      id: 'image',
      accessorKey: 'Asset_Icon_Full_Path',
      cell: AssetImageCell,
      size: 100,
    },
    {
      header: 'Current Driver',
      accessorFn: camera => {
        return camera.CurrentDriver || ''
      },
      id: 'currentDriver',
      accessorKey: 'CurrentDriver',
    },
    {
      header: 'Camera Type',
      accessorFn: camera => {
        return camera.idDevice_Type === 35 ? 'Surfsight' : 'SmartWitness'
      },
    },
    {
      header: 'Last Nearby Address',
      accessorKey: 'Last_Nearby_Address',
      meta: {
        excelWidth: 50,
      },
    },

    {
      header: 'Last Communication',
      accessorFn: camera => {
        return camera.Last_Location_DateTime_Local
          ? convertToUsersDateAndTimeFormat(
              new Date(camera.Last_Location_DateTime_Local),
            )
          : ''
      },
    },
    {
      header: 'Last Event',
      accessorKey: 'Last_Event',
    },
    {
      header: 'Status',
      accessorKey: 'CameraStatus',
      cell: (instance: CellContext<CameraListType, unknown>) => (
        <StatusBadge status={instance.row.original.CameraStatus} />
      ),
    },
    {
      header: 'Actions',
      id: 'Action',
      cell: (instance: CellContext<CameraListType, unknown>) =>
        ActionCell(instance, setRequestedCamera, setShowCameraUsage, navigate),
      enableResizing: false,
      enableSorting: false,
      meta: {
        disableExport: true,
      },
    },
  ]

  return (
    <ListContainer data-cy="cameras-list">
      {status === 'error' && <div>There was an error loading the cameras.</div>}

      <>
        <ResizableTable<CameraListType, number>
          data={cameras || []}
          columns={columns}
          title={'Camera List'}
          status={status}
          // initialFilters={initialFilters}
        >
          <CameraEventRequestVideo
            requestedCamera={!showCameraUsage ? requestedCamera : null}
            resetRequestedCamera={() => setRequestedCamera(null)}
          />
        </ResizableTable>
      </>
      {requestedCamera && (
        <CameraUsageDetail
          idAsset={requestedCamera?.idAsset}
          showDialog={showCameraUsage}
          assetLabel={requestedCamera?.Asset_Label || ''}
          onDismiss={() => {
            setShowCameraUsage(false)
            setRequestedCamera(null)
          }}
        />
      )}
    </ListContainer>
  )
}
export default CamerasList
