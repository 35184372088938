import * as React from 'react'
import styled from 'styled-components/macro'
import {sortBy} from 'lodash'
import {Broadcast, MapPinLine, User} from 'phosphor-react'
import Spinner from 'common/components/Spinner'
import {AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE, QUERIES} from 'common/constants'
import DCDTable from './DCDTable'
import {returnDCDValue} from './infoWindowHelpers'
import Marker from 'map/Marker'
import {createPoints} from 'map/mapHelpers'
import {getPopUpColumn} from 'account/customizeViews/customizeViewsHelper'

const AssetImageWrapper = styled.div`
  height: 130px;
  width: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  margin-right: 12px;
`
const AssetImage = styled.img`
  max-height: 100%;
  object-fit: contain;
  max-width: 182px !important;
`
const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  min-height: 23px;
  white-space: nowrap;
`
const LastEvent = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
const SpinnerContainer = styled.div`
  height: 160px;
`
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: flex-start;
  @media ${QUERIES.tabletAndUp} {
    padding: 0 20px;
  }
`
const AssetInfoColumn = styled(FlexCol)`
  border-right: 1px solid var(--asc-coolgray);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  padding: 0;
  @media ${QUERIES.tabletAndUp} {
    width: 440px;
    padding: 0 10px 0 20px;
  }
`
const DriverAndAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 14px;
  justify-content: space-evenly;
  height: 130px;
  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
  @media ${QUERIES.tabletAndUp} {
    height: 142px;
  }
`
const CurrentDriver = styled.div`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
`
const Address = styled.div`
  display: flex;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    max-height: 59px;
    max-width: 140px;
  }
  svg {
    min-width: 16px;
  }
  @media ${QUERIES.tabletAndUp} {
    span {
      max-height: 59px;
      max-width: 170px;
    }
  }
`
const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  @media ${QUERIES.tabletAndUp} {
    flex-direction: row;
  }
`
const MarkerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`

const AssetTab = ({
  asset,
  activeMarker,
  assetStatus,
  dcdStatus,
  dcdSchema,
  iconColor = '',
}) => {
  const [dcdRenderer, setDCDRenderer] = React.useState(null)
  React.useEffect(() => {
    if (dcdStatus === 'success' && dcdSchema) {
      const filteredPopupSchema = dcdSchema?.filter(
        schema => schema.fieldVisibility === 1,
      )
      const orderedSchema = sortBy(filteredPopupSchema, ['column', 'FieldSeq'])
      const popupSchema = orderedSchema.filter(
        schema => schema.column === 0 && schema.pinned === 0,
      )
      const popupData = getPopUpColumn(popupSchema)
      setDCDRenderer(popupData[0].items)
    }
  }, [dcdSchema, dcdStatus])

  const Icon_Path = asset?.Asset_Icon_Full_Path
  const point = asset && !Icon_Path ? createPoints([asset])[0] : null
  return (
    <>
      {assetStatus === 'loading' || asset.idAsset !== activeMarker.idAsset ? (
        <SpinnerContainer>
          <Spinner type="partial" />
        </SpinnerContainer>
      ) : (
        <TabContent>
          <AssetInfoColumn>
            <Row>
              <Item>
                <AssetImageWrapper>
                  {Icon_Path ? (
                    <AssetImage
                      src={
                        asset?.Asset_Icon_Full_Path
                          ? AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE +
                            '/' +
                            asset?.Asset_Icon_Full_Path
                          : ''
                      }
                    />
                  ) : (
                    <MarkerContainer>
                      <Marker point={point} type="card" iconColor={iconColor} />
                    </MarkerContainer>
                  )}
                </AssetImageWrapper>
              </Item>
              <DriverAndAddress>
                {asset?.Device.Last_Event && (
                  <LastEvent>
                    <Broadcast weight={'fill'} />
                    <span>{asset?.Device.Last_Event}</span>
                  </LastEvent>
                )}
                {asset?.CurrentDriver && (
                  <CurrentDriver>
                    <User />
                    <span>{asset.CurrentDriver}</span>
                  </CurrentDriver>
                )}
                {asset?.Device.Last_Landmark_Name ? (
                  <Address>
                    <MapPinLine />
                    <span>{asset?.Device?.Last_Landmark_Name}</span>
                  </Address>
                ) : asset?.Device.Last_Nearby_Address ? (
                  <Address>
                    <MapPinLine />
                    <span>{asset?.Device?.Last_Nearby_Address}</span>
                  </Address>
                ) : (
                  <Address>
                    <MapPinLine />
                    <span>No Location</span>
                  </Address>
                )}
              </DriverAndAddress>
            </Row>
          </AssetInfoColumn>
          <DCDTable
            dcdRenderer={dcdRenderer}
            returnDCDValue={returnDCDValue}
            asset={asset}
          />
        </TabContent>
      )}
    </>
  )
}
export default AssetTab
