import React, {useEffect, useReducer} from 'react'
import {Navigate} from 'react-router'
import styled from 'styled-components/macro'
import registerFaIcons from './assets/registerFaIcons'
import Header from './assets/Header'
import LeftMenu from './assets/LeftMenu'
import {reducer, initialState} from './managementContainerHelpers'
import ManagementRoutes from './components/ManagementRoutes'
import useManagementUsers from './hooks/useManagementUsers'
import {CustomerType} from './hooks/useCustomers'

registerFaIcons()

const ManagementOuterContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100vw - 240px);
`

const ManagementContainer = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // Users
  const {data: usersData, isFetched: usersFetched} = useManagementUsers()
  useEffect(() => {
    if (usersFetched) dispatch({type: 'setUsers', data: usersData})
  }, [usersData, usersFetched])

  const customerClicked = (row: CustomerType) =>
    dispatch({type: 'setSelected', data: row})

  const dealerClicked = (row: CustomerType) =>
    dispatch({type: 'setSelected', data: row})

  const clearSelected = () => dispatch({type: 'setSelected', data: null})

  // if (state.creds && state.creds.auth === 'invalid') return <div>Please Log In</div>
  if (state.error) return <Navigate to="/login" />

  return (
    <ManagementOuterContainer data-cy="management-container">
      <LeftMenu />
      <RightContainer>
        <Header creds={state.creds} />
        <ManagementRoutes
          data={state}
          customerClicked={customerClicked}
          dealerClicked={dealerClicked}
          clearSelected={clearSelected}
        />
      </RightContainer>
    </ManagementOuterContainer>
  )
}

export default ManagementContainer
