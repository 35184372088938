import * as React from 'react'
import {NavLink} from 'react-router'
import styled from 'styled-components/macro'
import {ascendColors} from './GlobalStyles'

type Props = {
  navigateTo?: string
  label: string
  onClick?: () => void
  dataTestId?: string
}

const Container = styled.div<{$fontOnSecondary: string}>`
  width: 100%;
  color: var(--primary);
  :hover {
    background-color: var(--secondary);
    color: ${p => p.$fontOnSecondary};
  }
`

const StyledNavLink = styled(NavLink)<{$fontOnSecondary: string}>`
  padding: 5px 15px 5px 15px;
  display: flex;
  color: var(--primary);
  :hover {
    background-color: var(--secondary);
    color: ${p => p.$fontOnSecondary};
  }
  height: 100%;
  width: 100%;
`

const StyledExternalLink = styled.div<{$fontOnSecondary: string}>`
  padding: 5px 15px 5px 15px;
  cursor: pointer;
  display: flex;
  color: var(--primary);
  :hover {
    background-color: var(--secondary);
    color: ${p => p.$fontOnSecondary};
  }
  height: 100%;
  width: 100%;
`

const FlyoutItem = ({navigateTo, label, onClick, dataTestId}: Props) => {
  return (
    <Container
      data-testid={`${dataTestId}-${label
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()}`}
      $fontOnSecondary={ascendColors.fontOnSecondary}
    >
      {navigateTo ? (
        <StyledNavLink
          to={navigateTo}
          aria-disabled={!navigateTo}
          $fontOnSecondary={ascendColors.fontOnSecondary}
        >
          {label}
        </StyledNavLink>
      ) : (
        <StyledExternalLink
          onClick={onClick}
          $fontOnSecondary={ascendColors.fontOnSecondary}
        >
          {label}
        </StyledExternalLink>
      )}
    </Container>
  )
}
export default FlyoutItem
