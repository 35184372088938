import Axios from 'axios'
import {UserConfig, useStore} from 'common/useStore'
import {setColorsBasedOnFlavor} from './helpers'
import {netAPIInstance} from './components/netAPIAxios'
import {APP_FLAVOR} from './constants'
import {setCustomColors} from './setTokenHelpers'
// import {activityDetector} from 'common/activityDetector'

// let activityDetectorStarted = false
type SetTokenType = {
  token?: string
  impersonating?: boolean
  remember?: boolean
  freshToken?: boolean
}

export const setToken = async ({
  token,
  impersonating,
  remember,
  freshToken = false,
}: SetTokenType) => {
  const user = useStore.getState().user
  // console.log('in setToken', user?.token, user.role);

  //page may have been refreshed, check session storage for token
  //if we are impersonating we will have a token but we need to get new one for
  //user we are impersonating
  if (!user?.token || impersonating || freshToken) {
    const newToken =
      token || localStorage.getItem('token') || sessionStorage.getItem('token')
    if (newToken) {
      // if (!activityDetectorStarted) {
      //   activityDetectorStarted = true
      //   activityDetector.init('active')
      //   // console.log('starting activity detector')
      //   activityDetector.on('idle', () => console.info('idle'))
      //   activityDetector.on('active', () => console.info('active'))
      // }
      const decodedToken = window.atob(newToken)
      const decodedTokenArray = decodedToken.split(':')
      const email = decodedTokenArray[0]
      let impersonatingEmail = ''
      if (decodedTokenArray.length > 2) {
        impersonatingEmail = decodedTokenArray[2]
      }
      const data = await setAxiosAndUser({
        token: newToken,
        email,
        impersonatingEmail,
        remember,
      })
      return data
    } else {
      return
    }
  }
}

type SetAxiosAndUserType = {
  token: string
  email: string
  impersonatingEmail?: string
  remember?: boolean
}

export const setAxiosAndUser = async ({
  token,
  email,
  impersonatingEmail,
  remember,
}: SetAxiosAndUserType) => {
  Axios.defaults.headers.common['Authorization'] = `Basic ${token}`
  netAPIInstance.defaults.headers.common['Authorization'] = `Basic ${token}`
  try {
    Axios.get('/users?a=getuser') //only so login date gets updated
    const {data}: {data: UserConfig} = await Axios.get('/users/authuser')
    let colorsSet = false
    if (data && data.Email_Address) {
      //update remember unless coming from logout
      if (remember !== undefined) {
        localStorage.setItem('remember', String(remember))
      }
      localStorage.setItem('token', token)
      if (remember) {
        if (!impersonatingEmail) {
          localStorage.setItem('lookup', token)
        }
      } else if (remember === false) {
        localStorage.removeItem('lookup')
      }
      if (data.Date_Format) {
        data.Date_Format = data.Date_Format.replace('mm', 'M')
        data.Date_Format = data.Date_Format.replace('m', 'M')
        data.Date_Format = data.Date_Format.replace('dd', 'd')
      } else {
        data.Date_Format = 'M/d/yyyy'
      }
      if (
        data.RestrictedAdminAccess &&
        data.RestrictedAdminAccess?.includes('account')
      ) {
        data.RestrictedAdminAccess = data.RestrictedAdminAccess + ',account'
      }

      if (data.CustomerConfigurations) {
        data.CustomerConfigurations?.forEach(
          (config: {ConfigGroup: string; ConfigSettings: string}) => {
            if (config.ConfigGroup === 'disabledfeatures') {
              data.disabledFeatures = config.ConfigSettings.split(',')
            }
            if (config.ConfigGroup === 'customcolors') {
              const colorsString = config.ConfigSettings.split('"')
              const colors = {
                primary: colorsString[3],
                secondary: colorsString[7],
                tertiary: colorsString[11] || '#a8a8ac',
              }
              document.body.style.setProperty('--primary', colors.primary)
              document.body.style.setProperty('--secondary', colors.secondary)
              document.body.style.setProperty('--tertiary', colors.tertiary)
              setCustomColors({
                primary: colors.primary,
                secondary: colors.secondary,
                tertiary: colors.tertiary,
              })
              colorsSet = true
            }
          },
        )
      }
      if (!colorsSet) {
        setColorsBasedOnFlavor()
      }
      useStore.setState({
        userConfig: data,
        user: {
          token,
          email,
          impersonatingEmail: impersonatingEmail || '',
          role: data?.Role_Id,
          Is_Support_Admin: data?.Is_Support_Admin,
        },
      })

      let parentCustomer = data.AvailableCustomers[0]?.ParentCustomers.find(
        c => c.HierarchyLevel === 1,
      )
      let parentName = parentCustomer?.Customer_Name
        ? parentCustomer.Customer_Name
        : data.Customer_Name
      //pendo config
      if (
        process.env.NODE_ENV !== 'development' &&
        process.env.NODE_ENV !== 'test'
      ) {
        const pendoData = {
          visitor: {
            id: data.Email_Address,
            idUser: APP_FLAVOR + '-' + data.idUser,
            email: data.Email_Address,
            firstName: data.First_Name,
            lastName: data.Last_Name,
          },
          account: {
            id: APP_FLAVOR + '-' + data.idCustomer,
            parentName,
            accountName: data.Customer_Name,
            environment: APP_FLAVOR,
          },
        }

        if (data.idCustomer && pendo) {
          pendo.initialize(pendoData)
        }
      }
    }
    return data
  } catch (error) {
    console.error(error)
    throw new Error()
  }
}
