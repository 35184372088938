import * as React from 'react'
import {useStore} from 'common/useStore'
import styled from 'styled-components/macro'
import Button from 'common/components/Button'
import {useNavigate} from 'react-router'
import {
  InfoWindowContainer,
  InfoWindowArrow,
} from 'map/infoWindow/infoWindowStyles'
import FindNearestAssets from 'mapControl/FindNearestAssets'
import {useMapStore} from 'map/useMapStore'
import {ascendColors} from 'common/GlobalStyles'

const StyledInfoWindowContainer = styled(InfoWindowContainer)`
  height: 130px;
  gap: 16px;
  padding: 16px;
  width: 227px;
  left: -116px;
  border-radius: 8px;
`

const StyledInfoWindowArrow = styled(InfoWindowArrow)`
  left: 0;
`

const StyledButton = styled(Button)<{
  $fontOnSecondary: string
  $fontOnPrimary: string
}>`
  text-transform: uppercase;
  background: var(--secondary);
  color: ${p => p.$fontOnSecondary};
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  height: 40px;
  width: 195px;
  :hover {
    color: ${p => p.$fontOnPrimary};
    background: var(--primary);
  }
`

const LocationInfoWindow = ({
  location,
  map,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lng,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lat,
}: {
  location: {
    id: number
    coordinates: {
      lat: number
      lng: number
    }
  }
  map: google.maps.Map
  lng: number
  lat: number
}) => {
  const userConfig = useStore.getState().userConfig
  const navigate = useNavigate()
  const splitAdminOptions = userConfig?.RestrictedAdminAccess
    ? userConfig.RestrictedAdminAccess.split(',')
    : []

  const showFindNearest =
    userConfig?.Role_Id === 1 ||
    (userConfig?.Role_Id === 11 &&
      splitAdminOptions.indexOf('locations') !== -1)

  return (
    <StyledInfoWindowContainer>
      <StyledButton
        onClick={() => navigate(`/locations/${location.id}`)}
        $fontOnPrimary={ascendColors.fontOnPrimary}
        $fontOnSecondary={ascendColors.fontOnSecondary}
      >
        edit location
      </StyledButton>
      {showFindNearest && (
        <StyledButton
          onClick={() => useMapStore.setState({clickedLocation: null})}
          $fontOnPrimary={ascendColors.fontOnPrimary}
          $fontOnSecondary={ascendColors.fontOnSecondary}
        >
          <FindNearestAssets
            lat={location.coordinates.lat}
            lng={location.coordinates.lng}
            map={map}
            clearAssetFilter={() => null}
          />
        </StyledButton>
      )}
      <StyledInfoWindowArrow>
        <div className="left">
          <div></div>
        </div>
        <div className="right">
          <div></div>
        </div>
      </StyledInfoWindowArrow>
    </StyledInfoWindowContainer>
  )
}

export default LocationInfoWindow
