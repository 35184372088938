import React from 'react'
import {useNavigate} from 'react-router'
import {useStore, ORoleType} from 'common/useStore'
import AppLayout from 'common/AppLayout'
import type {User} from './useStore'
import Spinner from 'common/components/Spinner'
import {setToken} from './setToken'

import Management from 'management/ManagementContainer'
import {stopSocket} from './webSockets/conversationsSockets'

const ManagementRoute = () => {
  stopSocket()
  const [validRoute, setValidRoute] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const user: User = useStore(state => state.user)
  React.useEffect(() => {
    document.title = 'Management'
  }, [])
  React.useEffect(() => {
    if (!user.token) {
      const logIn =
        localStorage.getItem('token') || sessionStorage.getItem('token')
      if (logIn) {
        setToken({})
      } else {
        navigate('/login')
      }
    } else {
      if (
        user?.role === ORoleType.SuperAdmin ||
        user?.role === ORoleType.DealerAdministrator
      ) {
        setValidRoute(true)
      } else {
        if (user?.role) {
          navigate('/map')
        }
      }
    }
  }, [navigate, user])
  if (validRoute === true) {
    return (
      <AppLayout showMainMenuHeader={false}>
        <Management />
      </AppLayout>
    )
  } else {
    return <Spinner />
  }
}

export default ManagementRoute
