import * as React from 'react'
import styled from 'styled-components/macro'
import FourOhFourTruck from 'images/fourohfour-truck.png'
import FourOhFourLine from 'images/line.png'
import {useRouteError} from 'react-router'
import {subSeconds} from 'date-fns'
import {Spinner} from './components'
import GeoforceAscend from 'images/geoforceascendlogo.svg'
const Container = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const StyledH1 = styled.h1`
  font-size: 5rem;
  color: var(--primary);
`

const Logo = styled.img`
  width: 15vw;
  height: auto;
`

const MessageContent = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-weight: 100;
      text-align: center;
      max-width: 1000px;
    }
  }
`

const TruckContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  position: absolute;
  left: 65vw;
  bottom: 200px;
  img {
    height: auto;
    width: 18vw;
  }
`

const LineContainer = styled.div`
  margin-top: 200px;
  width: 100%;
  height: 8px;
  position: absolute;
  left: 0;
  bottom: 200px;
  img {
    width: 85vw;
  }
`
const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`
const Error = () => {
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     alert(`Network error, please refresh the page.`)
  //   }, 1000)
  // }, [])

  const error: unknown = useRouteError()
  // console.info(error?.name, navigator.onLine)
  let message = ''
  let status = ''

  if (!navigator.onLine) {
    message =
      'It appears you are offline. Please check your connection and try again.'
    status = 'Offline'
  } else if ((error as Error)?.name === 'ChunkLoadError') {
    const lastReload = sessionStorage.getItem('last reload time')
    if (
      !lastReload ||
      new Date(lastReload).getTime() < subSeconds(new Date(), 60).getTime()
    ) {
      sessionStorage.setItem('last reload time', String(new Date()))
      window.location.reload()
    } else {
      message = 'Please refresh the page and try again.'
      status = 'Error'
    }
  } else {
    message = 'Please refresh the page and try again.'
    status = 'Error'
  }

  if (!message) return <Spinner />
  return (
    <Container>
      <Logo src={GeoforceAscend} />
      <StyledH1>{status}</StyledH1>
      <MessageContent>
        <h2>
          {message} <br /> If the issue persists, contact customer service.
        </h2>
      </MessageContent>
      <ImageContainer>
        <LineContainer>
          <img src={FourOhFourLine} />
        </LineContainer>
        <TruckContainer>
          <img src={FourOhFourTruck} />
        </TruckContainer>
      </ImageContainer>
    </Container>
  )
}
export default Error
