import io from 'socket.io-client'
import {useWebsocketStore} from './useWebsocketStore'
import {APP_WS} from 'common/constants'
import {utcToZonedTime} from 'date-fns-tz'
import {format, formatISO, parseISO} from 'date-fns'
import {calcGutAssetValues} from 'gut/gutHelpers'
import {useMapStore} from 'map/useMapStore'

export const startSocket = (socket, user, cache, userConfig) => {
  socket = io(APP_WS)

  socket.on('authenticated', () => {
    const socketListening = useWebsocketStore.getState().socketListening
    if (!socketListening) {
      authenticatedFunction(socket, cache, userConfig)
      useWebsocketStore.setState({socketListening: true})
    }
  })

  socket.on('authentication denied', function () {
    console.error(
      'Cannot enable live updates.',
      'An unexpected error occurred.',
    )
    socket.disconnect()
  })

  // socket.on('connect_error', error => {
  //   console.error(error)
  // })
  socket.on('connect', () => {
    //console.log(socket.id) // 'G5p5...'
    connectFunction(user, socket)
  })

  socket.on('error', e => {
    console.error(e)
  })

  return socket
}

export const connectFunction = (user, socket) => {
  let password
  const splitToken = window.atob(user.token).split(':')
  const username = splitToken[0]
  if (user.impersonatingEmail) {
    password = splitToken[1] + ':' + user.impersonatingEmail
  } else {
    password = splitToken[1]
  }

  socket.emit('authenticate', {
    username: username,
    password: password,
  })
}

// const dateFormats = {
//   'mm/dd/yyyy': 'M/d/yyyy',
//   'dd/mm/yyyy': 'd/M/yyyy',
//   'yyyy/mm/dd': 'yyyy/MM/dd',
// }

const convertDeviceForUser = (device, user) => {
  let convertedDevice = {...device}
  //conversion factors
  const mphToKph = 1.609344
  const milesToKm = 1.609344
  const gToL = 3.7854118
  const mpgToKml = 0.425143707
  const mphToL100km = 235.2145833
  const psiTokgcm2 = 0.070306957829636
  const psiTokpa = 6.89475728
  function fToC(fTemp) {
    return (fTemp - 32) * (5 / 9)
  }
  //convert dates to user format
  const newDateTime = parseISO(device.Last_Location_Time + 'Z')
  const localDateTime = utcToZonedTime(newDateTime, user.TZ_Moment_Name)

  convertedDevice.Last_Location_Date_Local = format(
    localDateTime,
    user.Date_Format,
  )
  convertedDevice.Last_Location_Time_Local = format(
    localDateTime,
    user.Time_Format === '12HR' ? 'h:mm a ' : 'HH:mm a ',
  )
  convertedDevice.Last_Location_DateTime_Local = formatISO(localDateTime)

  // If user has metric pref, then convert
  if (user.Distance_UOM.toLowerCase() === 'km') {
    convertedDevice.Estimated_GPS_Odometer *= milesToKm
  }

  if (user.Speed_UOM.toLowerCase() === 'km/h') {
    convertedDevice.Last_GPS_Speed *= mphToKph
    convertedDevice.Last_Engine_Speed *= mphToKph
  }

  if (
    user.Fuel_Rate_UOM.toLowerCase() === 'l/100 km' &&
    convertedDevice.Last_Fuel_Efficiency &&
    convertedDevice.Last_Fuel_Efficiency > 0
  ) {
    // mpg to L/100km
    convertedDevice.Last_Fuel_Efficiency =
      mphToL100km / device.Last_Fuel_Efficiency
  }

  if (
    user.Fuel_Rate_UOM.toLowerCase() === 'km/l' &&
    convertedDevice.Last_Fuel_Efficiency &&
    convertedDevice.Last_Fuel_Efficiency > 0
  ) {
    // mpg to km/l
    convertedDevice.Last_Fuel_Efficiency *= mpgToKml
  }

  if (user.Temperature_UOM.toLowerCase() === 'c') {
    //convert f to c
    convertedDevice.Last_Coolant_Temp = fToC(device.Last_Coolant_Temp)
    convertedDevice.Last_Battery_Temp = fToC(device.Last_Battery_Temp)
    convertedDevice.Last_Temp_Sensor1 = fToC(device.Last_Temp_Sensor1)
  }

  if (user.Volume_UOM.toLowerCase() === 'l') {
    convertedDevice.Last_Total_Fuel_Used *= gToL
    convertedDevice.Last_Total_Engine_Idle_Fuel *= gToL
  }

  if (
    user.Pressure_UOM.toLowerCase() === 'kg/cm2' ||
    user.Pressure_UOM.toLowerCase() === 'kpa'
  ) {
    const multiplier =
      user.Pressure_UOM.toLowerCase() === 'kg/cm2' ? psiTokgcm2 : psiTokpa
    if (device.Last_JBUS_Data) {
      convertedDevice.Last_JBUS_Data.sanyFrontPumpMainPressureHCU2
        ? (convertedDevice.Last_JBUS_Data.sanyFrontPumpMainPressureHCU2 *=
            multiplier)
        : ''
      convertedDevice.Last_JBUS_Data.sanyRearPumpMainPressureHCU2
        ? (convertedDevice.Last_JBUS_Data.sanyRearPumpMainPressureHCU2 *=
            multiplier)
        : ''
      convertedDevice.Last_JBUS_Data.engineOilPressure
        ? (convertedDevice.Last_JBUS_Data.engineOilPressure *= multiplier)
        : ''
    }
  }
  return convertedDevice
}

export const updateDevices = (data, cache) => {
  cache.setQueryData(['opening1'], opening1FromCache => {
    let newAssets = []
    const assetsFromCache = opening1FromCache?.assets
    if (assetsFromCache) {
      newAssets = [...assetsFromCache]
      data.forEach(deviceUpdate => {
        const assetToUpdateIndex = assetsFromCache.findIndex(
          a => a.idDevice === deviceUpdate.idDevice,
        )
        if (assetToUpdateIndex > -1) {
          const landmarkName = deviceUpdate.Last_Landmark_Name
            ? deviceUpdate.Last_Landmark_Name
            : ''

          newAssets[assetToUpdateIndex].Device = {
            ...assetsFromCache[assetToUpdateIndex].Device,
            Last_Landmark_Name: landmarkName,
            ...deviceUpdate,
          }
        }
      })
    }
    opening1FromCache.assets = newAssets
    const newGutValues = calcGutAssetValues(newAssets)

    useMapStore.setState({
      gutAssetData: newGutValues,
    })
    return opening1FromCache
  })
}

export const updateAlerts = (pendingAlertUpdates, cache) => {
  //update asset
  cache.setQueryData(['opening1'], opening1FromCache => {
    const assetsFromCache = opening1FromCache?.assets
    const newAssets = [...assetsFromCache]

    // console.info('applying alert updates', pendingAlertUpdates.length)
    if (assetsFromCache?.length > 0 && pendingAlertUpdates?.length > 0) {
      pendingAlertUpdates.forEach(au => {
        const assetToUpdateIndex = newAssets.findIndex(
          a => a.idDevice === au.idDevice,
        )
        if (assetToUpdateIndex > -1) {
          newAssets[assetToUpdateIndex].Alert_Count++
        }
      })
    }
    opening1FromCache.assets = newAssets
    return opening1FromCache
  })
  //update gut counts
  const gutAlertData = useMapStore.getState().gutAlertData

  //look for the type and when found see if the idAsset is already there
  //if not, add to number and add idAsset to assets array
  pendingAlertUpdates.forEach(au => {
    const alertToUpdateIndex = gutAlertData.types.findIndex(
      a => a.type === au.Alert_Type,
    )
    if (alertToUpdateIndex > -1) {
      //now look to see if the asset is already in the assets array
      if (!gutAlertData.types[alertToUpdateIndex].assets.includes(au.idAsset)) {
        gutAlertData.types[alertToUpdateIndex].count++
        gutAlertData.types[alertToUpdateIndex].assets.push(au.idAsset)
        gutAlertData.count++
      }
    } else {
      gutAlertData.types.push({
        type: au.Alert_Type,
        count: 1,
        assets: [au.idAsset],
      })
      gutAlertData.count++
    }
  })
  useMapStore.setState({
    gutAlertData,
  })
}

export const authenticatedFunction = (socket, cache, userConfig) => {
  socket.on('device update', data => {
    const pendingDeviceUpdates =
      useWebsocketStore.getState().pendingDeviceUpdates
    const newDevice = JSON.parse(data)
    const userDevice = convertDeviceForUser(newDevice, userConfig)
    const newDeviceUpdates = [...pendingDeviceUpdates, userDevice]
    useWebsocketStore.setState({pendingDeviceUpdates: newDeviceUpdates})
  })

  socket.on('alert', data => {
    const pendingAlertUpdates = useWebsocketStore.getState().pendingAlertUpdates
    const newAlerts = JSON.parse(data)
    const newAlertUpdates = [...pendingAlertUpdates, newAlerts]
    useWebsocketStore.setState({pendingAlertUpdates: newAlertUpdates})
  })
}
