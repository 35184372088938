import {
  DateRangePicker,
  InputDropdownOptional,
  RequiredAsterisk,
  Spinner,
  TextAreaWithLabel,
  Tooltip,
  RadioButtonGroup,
  StyledLabel,
  RadioGroup,
} from 'common/components'
import {InputDropdownOptionalProps} from 'common/components/InputDropdownOptional'
import {addDays} from 'date-fns'
import {showToast} from 'common/components/Toastr'
import {Info} from 'phosphor-react'
import * as React from 'react'
import {Range} from 'react-date-range'
import DurationInput from 'routingAndDispatch/common/DurationInput'
import useCustomerServiceTypes from 'routingAndDispatch/hooks/useCustomerServiceTypes'
import styled from 'styled-components/macro'
import useBulkPostOrdersByLocation from './hooks/useBulkPostOrdersByLocation'
import useGetRouteDefaultSettings from 'routingAndDispatch/hooks/useGetRouteDefaultSettings'
import {getSettingsDateObject} from 'routingAndDispatch/Stops/stopHelper'
import useLocationsIncludeShared from 'locations/hooks/useLocationsIncludeShared'
import {useNavigate} from 'react-router'
import Modal from 'common/Modal'

const StyledDateRangePicker = styled(DateRangePicker)`
  .range-container {
    width: 555px;
  }
  :focus {
    background-color: red !important;
  }
`
const StyledInfo = styled(Info)`
  margin-left: 5px;
`
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  gap: 25px;
  width: 100%;
  height: 100%;
  overflow: visible;
  > div {
    margin-bottom: 0 !important;
  }
`

const StyledInputDropdownOptional = styled(InputDropdownOptional)`
  ${'' /* width: 300px; */}
  & div > div {
    white-space: nowrap;
  }
`

const StyledDurationInput = styled(DurationInput)`
  width: 180px;
`
const Note = styled.span`
  font-size: 14px;
  line-height: 22px;
`

interface State {
  duration: {
    hrs: number
    mins: number
    total: number
  }
  description: string
  serviceType: string
  dateRange: Range
  calendarIsOpen: boolean
  orderType: string
  selectedLocationsType: 'Pickup' | 'Delivery'
  Multistop_idLocation?: number
}

type Location = {
  idLandmark: number
  Landmark_Name: string
}

type ActionTypes =
  | {type: 'setState'; data: Partial<State>}
  | {type: 'resetState'}

type ReducerType = [state: State, dispatch: (action: ActionTypes) => void]

const reducer = (state: State, action: ActionTypes): State => {
  switch (action.type) {
    case 'setState': {
      return {
        ...state,
        ...action.data,
      }
    }
    case 'resetState': {
      return {...initialState}
    }
    default:
      return state
  }
}

const initialState: State = {
  serviceType: '',
  description: '',
  dateRange: {
    startDate: addDays(new Date().setHours(8, 0), 0),
    endDate: addDays(new Date().setHours(17, 0), 0),
  },
  duration: {hrs: 0, mins: 30, total: 30},
  calendarIsOpen: false,
  orderType: 'Singlestop',
  selectedLocationsType: 'Pickup',
}

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  margin: 0;
`

const RadioGroupRow = styled.div`
  display: flex;
  gap: 16px;
`

interface Props {
  showAddOrdersDialog: boolean
  setShowAddOrdersDialog: (show: boolean) => void
  setSelectedIdLocations?: (idLocations: number[]) => void
  selectedIdLocations: number[]
}

const BulkOrderByLocationModal: React.FC<Props> = ({
  showAddOrdersDialog,
  setShowAddOrdersDialog,
  setSelectedIdLocations,
  selectedIdLocations,
}) => {
  const [state, dispatch]: ReducerType = React.useReducer(reducer, initialState)
  const navigate = useNavigate()
  const {data: serviceTypeData} = useCustomerServiceTypes(showAddOrdersDialog)
  const {data: locations} = useLocationsIncludeShared()
  const {data: settingsData, status: settingsStatus} =
    useGetRouteDefaultSettings()
  const {mutateAsync: postBulkOrders, status: bulkOrderStatus} =
    useBulkPostOrdersByLocation()

  React.useEffect(() => {
    if (
      settingsStatus === 'success' &&
      settingsData?.StartTime &&
      settingsData?.EndTime
    ) {
      const {ServiceDuration} = settingsData
      const hrs = Math.floor(ServiceDuration / 60)
      const mins = ServiceDuration % 60
      const startDate = getSettingsDateObject(
        settingsData.StartTime,
        settingsData.StartAMPM,
      )
      const endDate = getSettingsDateObject(
        settingsData.EndTime,
        settingsData.EndAMPM,
      )
      dispatch({
        type: 'setState',
        data: {
          duration: {
            hrs,
            mins,
            total: ServiceDuration,
          },
          dateRange: {
            startDate: startDate.dateObject,
            endDate: endDate.dateObject,
          },
        },
      })
    }
  }, [settingsData, settingsStatus])

  const ordersObject = {
    idLocations: selectedIdLocations,
    earliestDate: state.dateRange.startDate,
    latestDate: state.dateRange.endDate,
    serviceDuration: state.duration?.total || 30,
    description: state.description,
    serviceType: state.serviceType,
    orderType: state.orderType as 'Singlestop' | 'Multistop',
    selectedLocationsType: state.selectedLocationsType,
    Multistop_idLocation: state.Multistop_idLocation,
  }

  const postOrders = async () => {
    state.dateRange.startDate !== undefined &&
      state.dateRange.endDate !== undefined &&
      (await postBulkOrders(ordersObject))
  }

  const serviceTypeOptions = serviceTypeData?.map(
    (serviceType, index: number) => {
      return {
        key: index,
        value: serviceType.Name,
        text: serviceType.Name,
      }
    },
  )

  const handleDurationChange = ({
    actionType,
    value,
  }: {
    actionType: 'hours' | 'mins'
    value: number
  }) => {
    let newDurationState = undefined

    const currentMinutes = state.duration?.mins || 0
    const currentHours = state.duration?.hrs || 0

    if (actionType === 'hours') {
      newDurationState = {
        hrs: value,
        mins: currentMinutes,
      }
    } else {
      newDurationState = {
        hrs: currentHours,
        mins: value,
      }
    }

    const estimatedDurationInMinutes =
      actionType === 'hours'
        ? value * 60 + currentMinutes
        : currentHours * 60 + value
    dispatch({
      type: 'setState',
      data: {
        duration: {
          hrs: newDurationState?.hrs,
          mins: newDurationState?.mins,
          total: estimatedDurationInMinutes,
        },
      },
    })
  }

  const handleDismiss = () => {
    setShowAddOrdersDialog(false)
    dispatch({type: 'resetState'})
  }
  const handleDismissAfterSave = () => {
    setShowAddOrdersDialog(false)
    dispatch({type: 'resetState'})
    navigate('/routing/orders/list')
  }
  const setOrderType = (selected: string) => {
    dispatch({type: 'setState', data: {orderType: selected}})
  }
  const setSelectedLocationsType = (val: 'Pickup' | 'Delivery') => {
    dispatch({type: 'setState', data: {selectedLocationsType: val}})
  }
  const setSelectedLocation: InputDropdownOptionalProps['onChange'] = ({
    value,
  }) => {
    if (typeof value === 'number') {
      dispatch({type: 'setState', data: {Multistop_idLocation: value}})
    }
  }

  return (
    <Modal
      width="620px"
      height={state.orderType === 'Multistop' ? '870px' : '720px'}
      showDialog={showAddOrdersDialog}
      disabled={state.calendarIsOpen}
      title={`Add Orders for Selected Location${
        ordersObject.idLocations.length > 1 ? 's' : ''
      }`}
      onSave={() => {
        postOrders()
          .then(() => {
            showToast(`Orders have been added successfully!`, 'success')
            setSelectedIdLocations?.([])
            handleDismissAfterSave()
          })
          .catch(() =>
            showToast(
              `There was an error creating one or more orders. Please check the orders list and try again on the failed orders.`,
              'error',
            ),
          )
      }}
      onDismiss={handleDismiss}
      renderFooter={true}
    >
      {bulkOrderStatus === 'loading' && <Spinner />}
      <ModalContainer>
        <Note>
          <strong>Note:</strong> The orders created will have the same Start &
          End Time, Service Duration, Service Type, and Description. The
          Location Name will be used as the Order Name.
        </Note>
        <RadioGroupRow>
          <StyledLabel>Order Type:</StyledLabel>
          <StyledRadioButtonGroup
            options={[
              {text: 'Single Stop', value: 'Singlestop'},
              {text: 'Pickup & Delivery', value: 'Multistop'},
            ]}
            selected={state.orderType}
            setSelected={setOrderType}
          />
        </RadioGroupRow>
        {state.orderType === 'Multistop' && (
          <>
            {' '}
            <RadioGroupRow>
              <StyledLabel>
                Make the {ordersObject.idLocations.length} Selected Location
                {ordersObject.idLocations.length > 1 ? 's ' : ' '}
                the:
              </StyledLabel>
              <RadioGroup
                name="dailyDateRangeOption"
                options={[
                  {text: 'Pickup', value: 'Pickup'},
                  {text: 'Delivery', value: 'Delivery'},
                ]}
                direction="horizontal"
                selected={state.selectedLocationsType}
                setSelected={setSelectedLocationsType}
              />
            </RadioGroupRow>
            {locations && (
              <StyledInputDropdownOptional
                label={
                  state.selectedLocationsType === 'Delivery'
                    ? 'Pickup Location'
                    : 'Delivery Location'
                }
                options={locations.map((l: Location) => ({
                  key: l.idLandmark,
                  value: l.idLandmark,
                  text: l.Landmark_Name,
                }))}
                value={state.Multistop_idLocation || null}
                name="idLandmark"
                onChange={setSelectedLocation}
                search={true}
                clearable={true}
                required={true}
                errors={{}}
              />
            )}
          </>
        )}

        <StyledDateRangePicker
          setRange={e =>
            dispatch({
              type: 'setState',
              data: {
                dateRange: {...e},
              },
            })
          }
          range={state.dateRange}
          showDateAndTime={true}
          showPredefined={false}
          singleDay={false}
          minDate={new Date()}
          label="Start & End Time"
          required={true}
          className="date-picker"
          showTime={true}
          setIsOpen={(isOpen: boolean) =>
            dispatch({type: 'setState', data: {calendarIsOpen: isOpen}})
          }
        />
        <div>
          <StyledLabel>
            Est. service duration
            <RequiredAsterisk />
            <Tooltip
              tooltip={
                <ul>
                  <li>
                    This is the minimum amount of time drivers will need to
                    complete this stop.
                  </li>
                  <li>
                    {`The default time can be adjusted in Route Optimization > Setup > Route Default Settings.`}
                  </li>
                </ul>
              }
              position={'right center'}
              trigger={
                <StyledInfo color="var(--asc-moss)" weight="fill" size={16} />
              }
            />
          </StyledLabel>

          <StyledDurationInput
            onHourChange={value =>
              handleDurationChange({actionType: 'hours', value})
            }
            onMinuteChange={value =>
              handleDurationChange({actionType: 'mins', value})
            }
            value={state.duration}
          />
        </div>
        <InputDropdownOptional
          placeholder="Select Service Type"
          options={serviceTypeOptions}
          name={'serviceType'}
          label={'Service Type'}
          clearable={true}
          required={false}
          onChange={event =>
            dispatch({
              type: 'setState',
              data: {
                serviceType: event.value?.toString(),
              },
            })
          }
          value={state.serviceType || null}
          errors={{}}
        />
        <TextAreaWithLabel
          defaultValue={state.description}
          name={'description'}
          label={'Description'}
          rows={3}
          onChange={e =>
            dispatch({
              type: 'setState',
              data: {
                description: e.currentTarget.value,
              },
            })
          }
        />
      </ModalContainer>
    </Modal>
  )
}
export default BulkOrderByLocationModal
