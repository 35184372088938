import * as React from 'react'
import styled from 'styled-components/macro'
import {useNavigate} from 'react-router'
import {ChatCircleText} from 'phosphor-react'
import {RoutePlanType} from 'routingAndDispatch/types/typesModule'
import {Tooltip} from 'common/components'
import LineSegments from 'images/LineSegments.svg'
import {
  RoutePlanWithColor,
  SegmentWithColor,
} from 'routingAndDispatch/Routes/PlanReview/PlanReview'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  gap: 5px;
  grid-area: buttons;
`

const IconHolder = styled.div`
  border-radius: 5px;
  border: 1px solid var(--primary);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background: var(--secondary);
  }
`

interface Props {
  route: SegmentWithColor
  plan: RoutePlanType | RoutePlanWithColor
  data?: RoutePlanWithColor
}
const TableActionButtons: React.FC<Props> = ({route, plan, data}) => {
  const navigate = useNavigate()

  const idUser = route.idUser

  const passSegment = {
    planStartDateTime: data?.planStartDateTime || plan.planStartDateTime,
    planEndDateTime: data?.planEndDateTime || plan.planStartDateTime,
    planName: plan.planName,
    planStatus: plan.planStatus,
    idPlan: plan.idPlan,
    segments: data?.segments
      ? [
          {
            ...route,
            stops: data?.segments.filter(
              segment =>
                segment.idRouteDispatchDriver === route.idRouteDispatchDriver,
            )[0].stops,
          },
        ]
      : [route],
  }

  return (
    <Container>
      <Tooltip
        tooltip={'Start Conversation'}
        trigger={
          <IconHolder
            data-cy={'conversation-button'}
            onClick={() =>
              navigate('/routing/messages', {
                state: {
                  idUser: idUser,
                  from: '/routing/routes/' + plan.idPlan,
                },
              })
            }
          >
            <ChatCircleText weight="fill" size={20} color="var(--primary)" />
          </IconHolder>
        }
        position={'top center'}
      />
      <Tooltip
        tooltip={'Route Details'}
        trigger={
          <IconHolder
            data-cy={'route-detail-button'}
            onClick={e => {
              navigate(
                `/routing/routes/${plan.idPlan}/${passSegment.segments[0].idRouteDispatchDriver}`,
                {
                  state: {route: passSegment},
                },
              )
              e.stopPropagation()
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                cursor: 'pointer',
              }}
            >
              <foreignObject width="20" height="20">
                <img
                  src={LineSegments}
                  width="20"
                  height="20"
                  alt="LineSegments"
                />
              </foreignObject>
            </svg>
          </IconHolder>
        }
        position={'top center'}
      />
    </Container>
  )
}

export default TableActionButtons
