import * as React from 'react'
import styled from 'styled-components/macro'
import {useNavigate} from 'react-router'
import {Button, Form} from 'semantic-ui-react'
import {QUERIES} from 'common/constants'
import {resetPassword} from './forgotPasswordHelpers'
import LoginImage from 'images/geoforceLoading.jpg'
import {InputWithLabel} from 'common/components'
import AlertModal, {ButtonType} from 'common/components/AlertModal'
import {isEmail} from 'common/helpers'
import GeoforceAscend from 'images/geoforceascendlogo.svg'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
`

const LeftPanel = styled.div`
  padding: 50px;
  width: 100%;
  @media ${QUERIES.tabletAndUp} {
    width: 460px;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  &&& {
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      width: 460px;
      .field {
        width: 100%;
        margin-bottom: 30px;
      }
      input[type='text'],
      input[type='password'] {
        border: 1px solid var(--asc-coolgray);
        padding: 10px 15px;
        margin-right: 5px;
        background-color: white;
        font-size: 17px;
        border-style: solid;
        border-radius: 5px;
        :focus {
          border: 1px solid var(--asc-moss);
          box-sizing: border-box;
          box-shadow: 0px 0px 6px 1px var(--asc-keylime);
        }
      }
      label {
        /* margin-top: 8px; */
        color: black;
        font-size: 15px;
        font-weight: bold;
        span {
          font-weight: normal;
        }
      }
      .ui.checkbox {
        margin-top: 3px;
      }
      a {
        color: var(--primary);
        font-weight: bold;
        :hover {
          color: var(--secondary);
          span {
            color: var(--primary);
          }
        }
      }
    }
  }
`
const RightPanel = styled.div`
  display: flex;
  width: 0;
  @media ${QUERIES.tabletAndUp} {
    width: 100%;
  }
  border: none;
`
const Logo = styled.img`
  width: auto;
  max-height: 130px;
  margin-top: 30px;
`
const LoginButton = styled(Button)`
  &&& {
    padding: 10px 5px;
    background-color: var(--primary) !important;
    color: white !important;
    width: 270px;
    height: 50px;
    font-weight: 700 !important;
    font-size: 13px;
    :hover {
      background-color: var(--secondary) !important;
      color: var(--primary) !important;
    }
  }
`

// const StyledLabel = styled.label`
//   &&&& {
//     color: black;
//     font-size: 15px;
//     font-weight: bold;
//   }
// `

const ReturnToLoginLink = styled.a`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 1.1rem;
`

const HelpMessage = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 16px;
  margin-top: 20px;
  span {
    font-weight: bold;
  }
  a {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    color: var(--primary);
    font-weight: bold;
    margin-top: 5px;
    flex-wrap: wrap;
    span {
      font-weight: normal;
      margin-right: 4px;
    }
  }
  h1 {
    font-size: 20px;
    font-weight: normal;
    color: black;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
  }
`

const LoginHelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 25%;
  justify-content: flex-end;
  align-items: flex-start;
`
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 25%;
  align-content: center;
  align-items: center;
`

const ForgotPassword = () => {
  // const queryClient = useQueryClient()
  // const themeContext = React.useContext(ThemeContext)
  const [loading, setLoading] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const navigate = useNavigate()
  const [showDialog, setShowDialog] = React.useState(false)
  const [dialogTitle, setDialogTitle] = React.useState('')
  const [dialogText, setDialogText] = React.useState('')
  const [dialogType, setDialogType] = React.useState<ButtonType>('')

  const onSubmit = async () => {
    setLoading(true)
    if (email && isEmail(email)) {
      try {
        const response = await resetPassword(email)
        if (response === 'success') {
          setDialogTitle('Reset Password Email Sent')
          setDialogText('Please check your email for a login link')
          setDialogType('info')
          setShowDialog(true)
        } else {
          throw new Error(`reset password failed for ${email}`)
        }
      } catch (error) {
        setDialogTitle('Password reset failed')
        setDialogText('Please verify your Email address')
        setDialogType('error')
        setShowDialog(true)

        console.error(error)
      }
    }

    setLoading(false)
  }

  return (
    <React.Fragment>
      <Container>
        <LeftPanel>
          <LogoContainer>
            <Logo src={GeoforceAscend} />
          </LogoContainer>
          <h3>Forgot Your Password?</h3>
          <HelpMessage>
            We'll email you instructions to reset your password. If you don't
            have access to your email anymore, contact our support team at
            support@geoforce.com .
          </HelpMessage>
          <Form onSubmit={onSubmit}>
            {/* <Form.Field>
            <StyledLabel htmlFor="forgot-email">Email</StyledLabel>
            <input
              type="text"
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
              data-cy="forgot-email"
              id="forgot-email"
            />
          </Form.Field> */}
            <InputWithLabel
              data-cy="login-email"
              value={email}
              onChange={e => {
                setEmail(e.currentTarget.value.replace(/\s/g, ''))
              }}
              label="Email"
              name="email"
            />
            <LoginButton
              primary
              fluid
              size="large"
              type="submit"
              disabled={loading}
              loading={loading}
            >
              SEND EMAIL
            </LoginButton>
            <ReturnToLoginLink href="/">Return to Login</ReturnToLoginLink>
            <LoginHelpContainer>
              <HelpMessage>
                <span>Need help? </span>

                <a href={'mailto:support@geoforce.com'}>
                  <span>Email us at</span>
                  {'support@geoforce.com'}
                </a>
              </HelpMessage>
            </LoginHelpContainer>
          </Form>
        </LeftPanel>
        <RightPanel>
          <ImageContainer>
            <img src={LoginImage}></img>
          </ImageContainer>
        </RightPanel>
      </Container>
      <AlertModal
        showDialog={showDialog}
        onSave={() => {
          setShowDialog(false)
          navigate('/login')
        }}
        type={dialogType}
        title={dialogTitle}
        text={dialogText}
        showDenyButton={false}
        data-cy="dialog"
      />
    </React.Fragment>
  )
}
export default ForgotPassword
